// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Comment_comment__JkCA3 {
  display: flex;
  gap: 1.25rem;
  padding: 1.75rem 0;
  border-bottom: 1px solid var(--stroke-color);
}
.Comment_comment__JkCA3 .Comment_iconContainer__iXIp0 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--text-color);
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  flex-shrink: 0;
}
@media (min-width: 1024px) {
  .Comment_comment__JkCA3 .Comment_iconContainer__iXIp0 {
    width: 3.75rem;
    height: 3.75rem;
  }
}
.Comment_comment__JkCA3 .Comment_commentContent__Ou8gG {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  max-width: 40.625rem;
}
.Comment_comment__JkCA3 .Comment_commentContent__Ou8gG .Comment_commentAuthor__b5uqv {
  font-weight: 500;
  font-size: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/InWork/components/Comment.module.scss"],"names":[],"mappings":"AAAE;EACE,aAAA;EACA,YAAA;EACA,kBAAA;EACA,4CAAA;AACJ;AACI;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mCAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;AACN;AACM;EAVF;IAWI,cAAA;IACA,eAAA;EAEN;AACF;AACI;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,oBAAA;AACN;AACM;EACE,gBAAA;EACA,iBAAA;AACR","sourcesContent":["  .comment {\n    display: flex;\n    gap: 1.25rem;\n    padding: 1.75rem 0;\n    border-bottom: 1px solid var(--stroke-color);\n\n    .iconContainer {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      background-color: var(--text-color);\n      border-radius: 50%;\n      width: 3rem;\n      height: 3rem;\n      flex-shrink: 0;\n\n      @media (min-width: 1024px) {\n        width: 3.75rem;\n        height: 3.75rem;\n      }\n    }\n\n    .commentContent {\n      display: flex;\n      flex-direction: column;\n      gap: 0.25rem;\n      max-width: 40.625rem;\n\n      .commentAuthor {\n        font-weight: 500;\n        font-size: 1.5rem;\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comment": `Comment_comment__JkCA3`,
	"iconContainer": `Comment_iconContainer__iXIp0`,
	"commentContent": `Comment_commentContent__Ou8gG`,
	"commentAuthor": `Comment_commentAuthor__b5uqv`
};
export default ___CSS_LOADER_EXPORT___;
