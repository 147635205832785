// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal_modalOverlay__O9anN {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}

.Modal_modal__YFIXl {
  position: relative;
  width: 80%;
  background-color: var(--background-color);
  padding: 2rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 101;
}

.Modal_closeBtn__OB8F9 {
  position: absolute;
  top: 10px;
  right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/Modal.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,OAAA;EACA,MAAA;EACA,aAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;EACA,qCAAA;EAEA,aAAA;EACA,uBAAA;EACA,mBAAA;AAAF;;AAGA;EACE,kBAAA;EACA,UAAA;EACA,yCAAA;EACA,aAAA;EACA,mBAAA;EACA,yCAAA;EACA,YAAA;AAAF;;AAGA;EACE,kBAAA;EACA,SAAA;EACA,WAAA;AAAF","sourcesContent":[".modalOverlay {\n  position: fixed;\n  left: 0;\n  top: 0;\n  height: 100vh;\n  width: 100%;\n  overflow-x: hidden;\n  z-index: 100;\n  background-color: rgba(0, 0, 0, 0.75);\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.modal {\n  position: relative;\n  width: 80%;\n  background-color: var(--background-color);\n  padding: 2rem;\n  border-radius: 14px;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);\n  z-index: 101;\n}\n\n.closeBtn {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalOverlay": `Modal_modalOverlay__O9anN`,
	"modal": `Modal_modal__YFIXl`,
	"closeBtn": `Modal_closeBtn__OB8F9`
};
export default ___CSS_LOADER_EXPORT___;
