import Icon from "@mdi/react";
import { mdiInformationOutline } from "@mdi/js";
import { useEffect, useState } from "react";
import { EMAIL_REGEX, PWD_REGEX } from "../../utils/validation";
import { useSearchParams } from "react-router-dom";
import { parseConceptsIdQuery } from "../../utils/parseQuery";
import { cn } from "../../utils/cn";
import { Btn } from "../../components/ui/Btn";
import Input from "../../components/ui/Input";
import styles from "./RegisterForm.module.scss";

function RegisterForm({ authenticate, error, setError }) {
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [params] = useSearchParams();

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd]);

  useEffect(() => {
    setError("");
  }, [email, pwd, matchPwd]);

  const validateForm = () => {
    const v1 = EMAIL_REGEX.test(email);
    const v2 = PWD_REGEX.test(pwd);
    if (!v1 || !v2) {
      setError("Invalid Entry");
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    const apiData = { email, password: pwd };
    if (params.get("concepts_id")) {
      apiData.concepts_id = parseConceptsIdQuery(params.get("concepts_id"));
    }
    authenticate(apiData);
  };

  return (
    <div className={styles.registerFormContainer}>
      <form onSubmit={handleSubmit} className={styles.form}>
        <p
          className={cn(
            styles.errorMessage,
            error ? styles.errorVisible : styles.errorHidden
          )}
          aria-live="assertive"
        >
          {error}
        </p>
        <div className={styles.inputGroup}>
          <Input
            type="email"
            id="emailInput"
            variant="auth"
            autoComplete="off"
            placeholder="Email"
            required
            value={email}
            aria-invalid={validEmail ? "false" : "true"}
            aria-describedby="uidnote"
            onChange={(e) => setEmail(e.target.value.trim())}
            onFocus={() => setEmailFocus(true)}
            onBlur={() => setEmailFocus(false)}
            className={styles.input}
          />
          <small
            id="uidnote"
            className={cn(
              styles.infoMessage,
              emailFocus && !validEmail ? styles.infoVisible : styles.infoHidden
            )}
          >
            <span>
              <Icon path={mdiInformationOutline} size={0.7} />
              Please enter your email address.
            </span>
          </small>
        </div>
        <div className={styles.inputGroup}>
          <Input
            type="password"
            id="password"
            variant="auth"
            autoComplete="off"
            placeholder="Password"
            required
            value={pwd}
            aria-invalid={validPwd ? "false" : "true"}
            aria-describedby="pwdnote"
            onChange={(e) => setPwd(e.target.value)}
            onFocus={() => setPwdFocus(true)}
            onBlur={() => setPwdFocus(false)}
            className={styles.input}
          />
          <p
            id="pwdnote"
            className={cn(
              styles.infoMessage,
              pwdFocus && !validPwd ? styles.infoVisible : styles.infoHidden
            )}
          >
            <span className={styles.infoText}>
              <Icon path={mdiInformationOutline} size={0.7} />8 to 24
              characters.
            </span>
            Must include uppercase and lowercase letters, a number and a special
            character.
          </p>
        </div>
        <div className={styles.inputGroup}>
          <Input
            type="password"
            id="passwordConfirm"
            variant="auth"
            autoComplete="off"
            placeholder="Confirm password"
            required
            value={matchPwd}
            aria-invalid={validMatch ? "false" : "true"}
            aria-describedby="confirmnote"
            onChange={(e) => setMatchPwd(e.target.value)}
            onFocus={() => setMatchFocus(true)}
            onBlur={() => setMatchFocus(false)}
            className={styles.input}
          />
          <p
            id="confirmnote"
            className={cn(
              styles.infoMessage,
              matchFocus && !validMatch ? styles.infoVisible : styles.infoHidden
            )}
          >
            <span>
              <Icon path={mdiInformationOutline} size={0.7} />
              Must match the first password input field.
            </span>
          </p>
        </div>
        <Btn
          type="submit"
          variant="outline-gradient"
          className={styles.submitButton}
          disabled={!validEmail || !validPwd || !validMatch}
        >
          Create account
        </Btn>
      </form>
    </div>
  );
}

export default RegisterForm;
