const Pencil = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <path
        fill="#AF31F8"
        d="M25.887 8.8c.488-.487.488-1.3 0-1.762l-2.925-2.925c-.462-.488-1.274-.488-1.762 0L18.9 6.4l4.688 4.688M3.75 21.563v4.687h4.688l13.824-13.837-4.687-4.688L3.75 21.563z"
      ></path>
    </svg>
  );
};

export default Pencil;
