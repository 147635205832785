import React from "react";
import Select, { components } from "react-select";
import { ChevronDown } from "../icons";
import styles from "./DropdownImgTitle.module.scss";

const CustomDropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDown className={styles.selectIcon} />
    </components.DropdownIndicator>
  );
};

const DropdownImgTitle = ({ options, placeholder, onChange }) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "3.125rem",
      padding: 0,
      maxWidth: "65.5rem",
      width: "100%",
      borderRadius: "10px",
      borderColor: "#616164",
      boxShadow: "none",
      background: "transparent",
      overflow: "hidden",
      "&:hover": {
        borderColor: "#616164",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "1rem",
    }),
    menu: (provided) => ({
      ...provided,
      width: "100%",
      maxWidth: "65.5rem",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0,
      marginRight: "20px",
    }),
    option: (provided) => ({
      ...provided,
      padding: 0,
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
  };

  const formatOptionLabel = ({ label, image }) => {
    return (
      <div className="flex items-center gap-x-7">
        {image && (
          <img
            src={image}
            alt={label}
            width="36"
            height="36"
            className="me-2"
          />
        )}
        <span className="font-medium text-[24px]/none">{label}</span>
      </div>
    );
  };

  return (
    <Select
      options={options}
      styles={customStyles}
      formatOptionLabel={formatOptionLabel}
      onChange={onChange}
      components={{ DropdownIndicator: CustomDropdownIndicator }}
      placeholder={placeholder ? placeholder : "---Choose Item---"}
    />
  );
};

export default DropdownImgTitle;
