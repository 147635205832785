import Modal from "../../../components/ui/Modal";
import styles from './ConceptModal.module.scss';

function ConceptModal({close, modalData}) {

  return (
      <Modal close={close}>
        <div>
          <h1 className={styles.title}>{modalData?.name}</h1>
          <div className={styles.content}>
            <p>{modalData?.text || "No data available"}</p>
          </div>
        </div>
      </Modal>
  );
}

export default ConceptModal;
