import React, {Fragment, useEffect, useState} from "react";
import {Comments} from "./Comments";
import styles from "./InWorkConcept.module.scss";
import {cn} from "../../../utils/cn";
import {ChevronDown} from "../../../components/icons";
import moment from "moment";
import {createComment, fetchComments} from "../../../api/comment";
import {approveIssueStage, getIssue} from "../../../api/issue";
import {issueStageApprovedMessage, issueStages} from "../../../utils/constants/issue";
import IssueStages from "./IssueStages";

export const InWorkConcept = ({issueId}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [comments, setComments] = useState([]);
  const [issue, setIssue] = useState(null);

  const handleHeaderClick = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    const fetchData = async () => {
      const issue = await getIssue(issueId);
      setIssue(issue);
    }
    fetchData();
  }, [issueId, isOpen]);

  useEffect(() => {
    if (isOpen) {
      const fetchData = async () => {
        const comments = await fetchComments(issueId);
        setComments(comments);
      };
      fetchData();
    }
  }, [isOpen]);

  const approveStage = async (issue, issueConfig) => {
    const issueData = {
      stage: issueConfig.apiApprove
    };
    await approveIssueStage(issue.id, issueData);

    const commentData = {
      issue: issue.id,
      text: issueStageApprovedMessage.replace("{stage}", issueStages[issueConfig.stage])
    };
    await sendCommentApi(commentData, {system: true});
  };

  const addComment = async (text) => {
    const apiData = {
      text: text,
      issue: issue.id
    }
    await sendCommentApi(apiData);
  };

  const sendCommentApi = async (data, params) => {
    const newComment = await createComment(data, params);
    setComments([...comments, newComment]);
  }

  return (
      <Fragment>
        {issue && <div className={styles.inWorkConcept}>
          <div className={styles.header} onClick={handleHeaderClick}>
            <h3 className={styles.title}>{issue.concept.name}</h3>
            <div className={styles.info}>
              <span>{moment(issue.concept.created_at).format('DD.mm.yyyy')}</span>
              {/*<span>*/}
              {/*  Product:{" "}*/}
              {/*  <Link href="#" className={styles.link}>*/}
              {/*    Little Kitty 3D*/}
              {/*  </Link>*/}
              {/*</span>*/}
              {/*<span>*/}
              {/*  Offer:{" "}*/}
              {/*  <Link href="#" className={styles.link}>*/}
              {/*    543*/}
              {/*  </Link>*/}
              {/*</span>*/}
              <span>Status: {issue.status} - {issueStages[issue.stage] || "No stage"}</span>
              <span className={styles.price}>${issue.concept.price}</span>
              <ChevronDown
                  className={cn(styles.chevron, isOpen && styles.chevronOpen)}
              />
            </div>
          </div>
          {isOpen && (
              <div className={styles.details}>
                <div className={styles.description}>
                  <p className={styles.descriptionText}>
                    {issue.concept.text}
                  </p>
                  {/*<div className={styles.additionalInfo}>*/}
                  {/*  <span>Type: 2D</span>*/}
                  {/*  <span>Duration: 22 sec</span>*/}
                  {/*</div>*/}
                </div>
                <IssueStages issue={issue} approveStage={approveStage}/>
                <Comments comments={comments} addComment={addComment}/>
              </div>
          )}
        </div>
        }</Fragment>
  );
};
