// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RegisterForm_registerFormContainer__vj14C {
  display: flex;
  text-align: center;
}

.RegisterForm_form__a7MJb {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.RegisterForm_errorMessage__cY4YR {
  display: flex;
  justify-content: center;
  color: #e3342f;
}

.RegisterForm_errorVisible__j7tux {
  display: flex;
}

.RegisterForm_errorHidden__C-kT3 {
  display: none;
}

.RegisterForm_inputGroup__A3rYF {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.75rem;
}

.RegisterForm_input__ntmgp {
  width: 21.875rem;
}

.RegisterForm_infoMessage__1xp-o {
  color: var(--scroll-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: white;
  max-width: 320px;
  width: 100%;
}
.RegisterForm_infoMessage__1xp-o span {
  display: flex;
  gap: 0.25rem;
  align-items: center;
}

.RegisterForm_infoVisible__F-ZZQ {
  display: flex;
}

.RegisterForm_infoHidden__\\+Nt8v {
  display: none;
}

.RegisterForm_infoText__GbSSW {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.RegisterForm_submitButton__a1vkV {
  margin-top: 2rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  width: auto;
}
@media (min-width: 1024px) {
  .RegisterForm_submitButton__a1vkV {
    margin-top: 6rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Auth/RegisterForm.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,cAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,sBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,0BAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,YAAA;EACA,gBAAA;EACA,WAAA;AACF;AACE;EACE,aAAA;EACA,YAAA;EACA,mBAAA;AACJ;;AAGA;EACE,aAAA;AAAF;;AAGA;EACE,aAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;AAAF;;AAGA;EACE,gBAAA;EAMA,oBAAA;EACA,qBAAA;EACA,WAAA;AALF;AADE;EAHF;IAII,gBAAA;EAIF;AACF","sourcesContent":[".registerFormContainer {\n  display: flex;\n  text-align: center;\n}\n\n.form {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.errorMessage {\n  display: flex;\n  justify-content: center;\n  color: #e3342f;\n}\n\n.errorVisible {\n  display: flex;\n}\n\n.errorHidden {\n  display: none;\n}\n\n.inputGroup {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-bottom: 0.75rem;\n}\n\n.input {\n  width: 21.875rem;\n}\n\n.infoMessage {\n  color: var(--scroll-color);\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 0.5rem;\n  margin-top: 0.5rem;\n  font-size: 0.75rem;\n  color: white;\n  max-width: 320px;\n  width: 100%;\n\n  & span {\n    display: flex;\n    gap: 0.25rem;\n    align-items: center;\n  }\n}\n\n.infoVisible {\n  display: flex;\n}\n\n.infoHidden {\n  display: none;\n}\n\n.infoText {\n  display: flex;\n  align-items: center;\n  gap: 0.25rem;\n}\n\n.submitButton {\n  margin-top: 2rem;\n\n  @media (min-width: 1024px) {\n    margin-top: 6rem;\n  }\n\n  padding-left: 2.5rem;\n  padding-right: 2.5rem;\n  width: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"registerFormContainer": `RegisterForm_registerFormContainer__vj14C`,
	"form": `RegisterForm_form__a7MJb`,
	"errorMessage": `RegisterForm_errorMessage__cY4YR`,
	"errorVisible": `RegisterForm_errorVisible__j7tux`,
	"errorHidden": `RegisterForm_errorHidden__C-kT3`,
	"inputGroup": `RegisterForm_inputGroup__A3rYF`,
	"input": `RegisterForm_input__ntmgp`,
	"infoMessage": `RegisterForm_infoMessage__1xp-o`,
	"infoVisible": `RegisterForm_infoVisible__F-ZZQ`,
	"infoHidden": `RegisterForm_infoHidden__+Nt8v`,
	"infoText": `RegisterForm_infoText__GbSSW`,
	"submitButton": `RegisterForm_submitButton__a1vkV`
};
export default ___CSS_LOADER_EXPORT___;
