const KeyFill = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        fill="#1E1E1E"
        d="M31.637 7.477a11.345 11.345 0 00-6.773-3.395 11.193 11.193 0 00-7.353 1.666c-2.2 1.393-3.882 3.51-4.773 6.01-.891 2.499-.94 5.236-.14 7.768l-7.925 8.18c-.214.218-.384.48-.5.767a2.414 2.414 0 00-.173.908v4.25c0 .628.242 1.23.673 1.675A2.26 2.26 0 006.296 36h4.593c.304 0 .596-.125.812-.347.215-.222.336-.524.336-.838v-2.37h2.296c.305 0 .597-.125.812-.347.215-.222.336-.524.336-.838v-2.37h2.297a1.116 1.116 0 00.812-.346l1.372-1.418c1.148.386 2.349.581 3.556.58h.015a11.23 11.23 0 006.373-2.004 11.778 11.778 0 004.223-5.319 12.203 12.203 0 00.65-6.843 11.972 11.972 0 00-3.142-6.063zm-5.248 7.786c-.455 0-.898-.139-1.276-.4a2.356 2.356 0 01-.846-1.063 2.44 2.44 0 01-.13-1.37c.088-.459.307-.881.628-1.213.321-.331.73-.557 1.176-.648a2.23 2.23 0 011.326.135c.42.18.779.483 1.03.873.253.39.388.848.388 1.316a2.41 2.41 0 01-.673 1.676 2.26 2.26 0 01-1.623.694z"
      ></path>
    </svg>
  );
};

export default KeyFill;
