import { mdiPlus } from "@mdi/js";
import { useLoaderData } from "react-router-dom";
import DropdownImgTitle from "../../components/ui/DropdownImgTitle";
import { useState } from "react";
import ConceptsList from "./components/ConceptsList";
import { Btn } from "../../components/ui/Btn";
import styles from "./Concepts.module.scss";

function Concepts() {
  const products = useLoaderData();
  const [selectedProduct, setSelectedProduct] = useState(null);

  let options = [];

  if (products) {
    products.map((prd) => {
      if (prd.source_forms.length && prd.source_forms[0]) {
        const sourceForm = prd.source_forms[0];
        return options.push({
          value: prd.id,
          label: sourceForm.title,
          image: sourceForm.icon,
        });
      }
    });
  }

  const handleChange = (selected) => {
    const product = products.find((prd) => {
      return prd.id === selected.value;
    });
    setSelectedProduct(product);
  };

  return (
    <section className={styles.conceptsSection}>
      <div className={styles.conceptsContainer}>
        <h2 className={styles.title}>Concepts</h2>
        <h3 className={styles.subtitle}>Product:</h3>
        <div className={styles.controls}>
          <DropdownImgTitle options={options} onChange={handleChange} />
          <Btn to="/profile/products/new" icon={mdiPlus}>
            Add product
          </Btn>
        </div>
         {selectedProduct && <ConceptsList product={selectedProduct} />}
      </div>
    </section>
  );
}

export default Concepts;
