import styles from './Stage.module.scss';
import {mdiCheck} from "@mdi/js";
import {Btn} from "../../../components/ui/Btn";
import {issueStages} from "../../../utils/constants/issue";
import {cn} from "../../../utils/cn";
import {useState} from "react";

function Stage({issue, config, approve}) {

  const [isApproved, setIsApproved] = useState(config.next.includes(issue.stage));
  const isUpcoming = config.previous.includes(issue.stage);

  const handleClick = async () => {
    await approve(issue, config);
    setIsApproved(true);
  };

  return (
      <div className={styles.stage}>
        <h4 className={styles.heading}>{issueStages[config.stage]}:</h4>
        <div className={styles.description}>
          <p
              className={
                cn(styles.descriptionText, isApproved || isUpcoming || !issue.stage ? styles.disabledText : '')
              }
          >
            {config.hint}
          </p>
        </div>
        {issue.stage && isApproved &&
            <Btn disabled icon={mdiCheck} variant="outline-accent">Approved</Btn>}
        {issue.stage && !isApproved && !isUpcoming &&
            <Btn onClick={handleClick}>Approve Stage</Btn>}
        {(!issue.stage || isUpcoming) && <Btn disabled>Approve Stage</Btn>}
      </div>
  )
}

export default Stage;