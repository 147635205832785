import React from "react";
import ReactDom from "react-dom";
import styles from './Modal.module.scss'
import {cn} from "../../utils/cn";
import Icon from '@mdi/react';
import {mdiClose} from '@mdi/js';


const portalElement = document.getElementById("app-modal");

function ModalOverlay({children, ...rest}) {

  return (
      <div
          onClick={rest.onClick}
          className={cn(
              styles.modalOverlay,
              rest.className
          )}
      >
        {children}
      </div>
  );
}

function Modal({close, children, maxWidth, ...rest}) {

  return ReactDom.createPortal(
      <ModalOverlay onClick={close} {...rest}>
        <div
            className={styles.modal}
            style={{ maxWidth }}
            onClick={(e) => e.stopPropagation()}
        >
          <button
              className={styles.closeBtn}
              onClick={close}
          >
            <Icon path={mdiClose} size={1.5}/>
          </button>
          {children}
        </div>
      </ModalOverlay>,
      portalElement
  );
}

export default Modal;
