// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConceptsList_conceptsList__-DOlV {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.ConceptsList_header__yihUX {
  margin-top: 5rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;
}
@media (min-width: 1024px) {
  .ConceptsList_header__yihUX {
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
  }
}

.ConceptsList_stats__Cuxxo {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media (min-width: 1024px) {
  .ConceptsList_stats__Cuxxo {
    margin-left: auto;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3.5rem;
  }
}

.ConceptsList_stat__tqubI {
  font-size: 2.25rem;
}

.ConceptsList_count__-Q1iy {
  font-weight: 500;
  color: var(--accent-color);
}

.ConceptsList_conceptList__QZjCX {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin-top: 2.5rem;
}

.ConceptsList_noConcepts__0q3iY {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
}

.ConceptsList_noConceptsText__f9SFl {
  font-size: 2.25rem;
  color: var(--scroll-color);
}

.ConceptsList_loading__L7tYN {
  width: 150px;
  align-self: center;
  margin-top: 45px;
}

.ConceptsList_error__JlwyU {
  width: 100%;
  color: var(--fail-color);
  font-size: 1.25rem;
  text-align: end;
}`, "",{"version":3,"sources":["webpack://./src/pages/Concepts/components/ConceptsList.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,sBAAA;AACF;;AAEA;EACE,gBAAA;EACA,aAAA;EACA,eAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;AACF;AACE;EARF;IASI,mBAAA;IACA,mBAAA;IACA,gBAAA;EAEF;AACF;;AACA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAEF;AAAE;EALF;IAMI,iBAAA;IACA,mBAAA;IACA,mBAAA;IACA,uBAAA;IACA,WAAA;EAGF;AACF;;AAAA;EACE,kBAAA;AAGF;;AAAA;EACE,gBAAA;EACA,0BAAA;AAGF;;AAAA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,kBAAA;AAGF;;AAAA;EACE,aAAA;EACA,SAAA;EACA,uBAAA;EACA,mBAAA;AAGF;;AAAA;EACE,kBAAA;EACA,0BAAA;AAGF;;AAAA;EACE,YAAA;EACA,kBAAA;EACA,gBAAA;AAGF;;AAAA;EACE,WAAA;EACA,wBAAA;EACA,kBAAA;EACA,eAAA;AAGF","sourcesContent":[".conceptsList {\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n}\n\n.header {\n  margin-top: 5rem;\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: column;\n  gap: 2rem;\n  margin-bottom: 2rem;\n\n  @media (min-width: 1024px) {\n    flex-direction: row;\n    align-items: center;\n    margin-bottom: 0;\n  }\n}\n\n.stats {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n\n  @media (min-width: 1024px) {\n    margin-left: auto;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n    gap: 3.5rem;\n  }\n}\n\n.stat {\n  font-size: 2.25rem;\n}\n\n.count {\n  font-weight: 500;\n  color: var(--accent-color);\n}\n\n.conceptList {\n  display: flex;\n  flex-direction: column;\n  gap: 2.5rem;\n  margin-top: 2.5rem;\n}\n\n.noConcepts {\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n}\n\n.noConceptsText {\n  font-size: 2.25rem;\n  color: var(--scroll-color);\n}\n\n.loading {\n  width: 150px;\n  align-self: center;\n  margin-top: 45px;\n}\n\n.error {\n  width: 100%;\n  color: var(--fail-color);\n  font-size: 1.25rem;\n  text-align: end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"conceptsList": `ConceptsList_conceptsList__-DOlV`,
	"header": `ConceptsList_header__yihUX`,
	"stats": `ConceptsList_stats__Cuxxo`,
	"stat": `ConceptsList_stat__tqubI`,
	"count": `ConceptsList_count__-Q1iy`,
	"conceptList": `ConceptsList_conceptList__QZjCX`,
	"noConcepts": `ConceptsList_noConcepts__0q3iY`,
	"noConceptsText": `ConceptsList_noConceptsText__f9SFl`,
	"loading": `ConceptsList_loading__L7tYN`,
	"error": `ConceptsList_error__JlwyU`
};
export default ___CSS_LOADER_EXPORT___;
