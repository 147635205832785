import {useState} from "react";
import {cn} from "../../../utils/cn";
import {ChevronDown, Gear} from "../../../components/icons";
import styles from "./ProductsListItem.module.scss";
import {useNavigate} from "react-router-dom";

export const ProductsListItem = ({product}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const sourceForm = product.source_forms.length && product.source_forms[0];

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const trimDescription = (text) => text.slice(0, 300) + "...";

  return (
      <div className={styles.productItem}>
        <div
            className={cn(styles.itemHeader, isOpen && styles.buttonOpen)}
        >
          <div className={styles.iconContainer}>
            <img
                src={sourceForm.icon}
                className={styles.productIcon}
                alt="product icon"
            />
            <h2 className={styles.productTitle}>{sourceForm.title}</h2>
          </div>
          <div className={styles.actionIcons}>
            <button onClick={() => navigate(`/profile/products/${product.id}`)}>
              <Gear/>
            </button>
            <button onClick={toggleCollapse}>
              <ChevronDown
                  className={cn(styles.chevron, isOpen && styles.chevronOpen)}
              />
            </button>
          </div>
        </div>
        <div
            className={cn(
                styles.collapseContent,
                isOpen ? styles.contentOpen : styles.contentClosed
            )}
        >
          <div className={styles.details}>
            <div className={styles.orderStats}>
              {trimDescription(sourceForm.description)}
            </div>
            <div className="d-flex flex-wrap">
              {sourceForm.categories.map((cat, index) => <div className={styles.chip}
                                                              key={index}>{cat.name}</div>)}
            </div>
            {/*<ul className={styles.detailsList}>*/}
            {/*  <li className={styles.orderItem}>*/}
            {/*    <div className={styles.orderHeader}>*/}
            {/*      <div className={styles.orderInfo}>*/}
            {/*        <h3 className={styles.orderTitle}>Order 54</h3>*/}
            {/*        <div className={styles.orderStats}>*/}
            {/*          <span className={styles.state}>State: in work</span>*/}
            {/*          <span className={styles.creative}>Creative: 5/10</span>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*      <button*/}
            {/*          type="button"*/}
            {/*          onClick={() => navigate("/profile/in_work")}*/}
            {/*          className={styles.dotsButton}*/}
            {/*      >*/}
            {/*        <HorizontalDots/>*/}
            {/*      </button>*/}
            {/*    </div>*/}
            {/*  </li>*/}
            {/*</ul>*/}
            {/*<div className={styles.buttons}>*/}
            {/*  <Btn onClick={() => {*/}
            {/*  }} icon={mdiPlus}>*/}
            {/*    Add order*/}
            {/*  </Btn>*/}
            {/*  <Btn variant="outline-accent" to="/profile/concepts">*/}
            {/*    View All Concepts*/}
            {/*  </Btn>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
  );
};
