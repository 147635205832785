import { useLayoutEffect, useState } from "react";
import styles from "./SwitchTheme.module.scss";

export const SwitchTheme = () => {
  const [isGreenTheme, setIsGreenTheme] = useState(() => {
    const savedTheme = localStorage.getItem("isGreenTheme");
    return savedTheme ? JSON.parse(savedTheme) : false;
  });

  useLayoutEffect(() => {
    document.documentElement.setAttribute(
      "data-theme",
      isGreenTheme ? "green" : "default"
    );
    localStorage.setItem("isGreenTheme", JSON.stringify(isGreenTheme));
  }, [isGreenTheme]);

  const toggleTheme = () => {
    setIsGreenTheme((prev) => !prev);
  };

  return (
    <div className={styles.switchTheme}>
      <label className={styles.switchLabel}>
        <span className={styles.switchLabelText}>Change Theme:</span>
        <input
          type="checkbox"
          checked={isGreenTheme}
          onChange={toggleTheme}
          className={`${styles.switchInput} ${
            isGreenTheme ? styles.greenBackground : styles.accentBackground
          }`}
        />
        <span
          className={`${styles.switchToggle} ${
            isGreenTheme ? styles.toggleOn : styles.toggleOff
          }`}
        />
      </label>
    </div>
  );
};
