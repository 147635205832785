import {createSlice} from "@reduxjs/toolkit";

const heygenSlice = createSlice({
  name: "heygen",
  initialState: {
    key: "",
    voiceoverText: ""
  },
  reducers: {
    setKey(state, action) {
      state.key = action.payload;
    },
    setVoiceoverText(state, action) {
      state.voiceoverText = action.payload;
    }
  }
});

export const heygenActions = heygenSlice.actions;
export default heygenSlice;
