const File = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      fill="none"
      viewBox="0 0 100 100"
    >
      <path
        stroke="#C3B2CC"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
        d="M59.108 11.47v14.984c0 2.03.813 3.98 2.25 5.417a7.708 7.708 0 005.442 2.246h17.187"
      ></path>
      <path
        stroke="#C3B2CC"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
        d="M31.863 31.863h13.604M31.863 50.001h36.275M31.861 68.138h36.276m16.237-32.437v35.7A17.713 17.713 0 0178.7 83.776a17.846 17.846 0 01-12.8 4.75H34.275a17.892 17.892 0 01-12.917-4.684 17.77 17.77 0 01-5.733-12.441V28.592A17.71 17.71 0 0121.3 16.217a17.846 17.846 0 0112.8-4.75h23.808a14.596 14.596 0 019.838 3.771L80.079 26.58a12.382 12.382 0 014.296 9.12z"
      ></path>
    </svg>
  );
};

export default File;
