import {Outlet} from "react-router-dom";
import {useRef, useState} from "react";
import Sidebar from "./Sidebar";
import {ProfileNav} from "./ProfileNav";
import styles from "./Profile.module.scss";
import {Scrollbars} from "react-custom-scrollbars-2";
import ScrollToTop from "../ScrollToTop";

export default function Profile() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollbarRef = useRef(null);

  const handleScrollFrame = (values) => {
    setScrollPosition(values.scrollTop);
  };

  return (
      <div className={styles.profileContainer}>
        <Sidebar/>
        <div className={styles.profileContent}>
          <ProfileNav/>
          <Scrollbars
              ref={scrollbarRef}
              onScrollFrame={handleScrollFrame}
              hideTracksWhenNotNeeded={true}
              renderView={(props) => (
                  <div {...props} className={styles.scrollbarContainer}></div>
              )}
              renderTrackHorizontal={(props) => (
                  <div {...props} className={styles.horizontalTrack}/>
              )}
              renderTrackVertical={(props) => (
                  <div className={styles.verticalTrack}/>
              )}
              renderThumbVertical={() => (
                  <div className={styles.verticalThumb}></div>
              )}
          >
            <Outlet/>
          </Scrollbars>
          <ScrollToTop
              scrollPosition={scrollPosition}
              scrollbarRef={scrollbarRef}
          />
        </div>
      </div>
  );
}
