// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScrollToTop_scrollToTop__VBdCR {
  position: fixed;
  bottom: 20px;
  right: 15px;
  z-index: 49;
}
@media (min-width: 768px) {
  .ScrollToTop_scrollToTop__VBdCR {
    right: 35px;
  }
}
@media (min-width: 1044px) {
  .ScrollToTop_scrollToTop__VBdCR {
    bottom: 70px;
    right: 65px;
  }
}

.ScrollToTop_scrollButton__B7CyC {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 3.75rem;
  height: 3.75rem;
  background: var(--accent-color);
  cursor: pointer;
}
.ScrollToTop_scrollButton__B7CyC svg {
  fill: #fff;
  transform: rotate(180deg);
  margin-bottom: 4px;
  width: 2.25rem;
  height: 2.25rem;
}`, "",{"version":3,"sources":["webpack://./src/components/ScrollToTop.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;EACA,WAAA;EACA,WAAA;AACF;AAAE;EALF;IAMI,WAAA;EAGF;AACF;AADE;EATF;IAUI,YAAA;IACA,WAAA;EAIF;AACF;;AADA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;EACA,+BAAA;EACA,eAAA;AAIF;AAHE;EACE,UAAA;EACA,yBAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;AAKJ","sourcesContent":[".scrollToTop {\n  position: fixed;\n  bottom: 20px;\n  right: 15px;\n  z-index: 49;\n  @media (min-width: 768px) {\n    right: 35px;\n  }\n\n  @media (min-width: 1044px) {\n    bottom: 70px;\n    right: 65px;\n  }\n}\n\n.scrollButton {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 50%;\n  width: 3.75rem;\n  height: 3.75rem;\n  background: var(--accent-color);\n  cursor: pointer;\n  & svg {\n    fill: #fff;\n    transform: rotate(180deg);\n    margin-bottom: 4px;\n    width: 2.25rem;\n    height: 2.25rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scrollToTop": `ScrollToTop_scrollToTop__VBdCR`,
	"scrollButton": `ScrollToTop_scrollButton__B7CyC`
};
export default ___CSS_LOADER_EXPORT___;
