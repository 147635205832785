// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConceptCard_conceptCard__MmNzV {
  border: 1px solid var(--stroke-color);
  border-radius: 0.625rem;
}

.ConceptCard_header__i2GMY {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.75rem;
}

.ConceptCard_name__t40\\+- {
  font-weight: 500;
  font-size: 1.875rem;
}

.ConceptCard_priceContainer__jjidt {
  display: flex;
  align-items: center;
  gap: 1.75rem;
  font-size: 1.5rem;
  line-height: normal;
}

.ConceptCard_price__-CzMv {
  font-weight: 500;
}

.ConceptCard_details__GlEvd {
  padding: 1.75rem 1.5rem;
  border-top: 1px solid var(--stroke-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 3.5rem;
}
@media (min-width: 1024px) {
  .ConceptCard_details__GlEvd {
    padding: 1.75rem 2.5rem;
    flex-direction: row;
  }
}

.ConceptCard_info__9q8be {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
}

.ConceptCard_meta__6dpQs {
  display: flex;
  gap: 1.75rem;
  font-weight: 500;
  font-size: 1.5rem;
}

.ConceptCard_actions__wOcrP {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/Concepts/components/ConceptCard.module.scss"],"names":[],"mappings":"AAAA;EACE,qCAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,qBAAA;AACF;;AAEA;EACE,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,iBAAA;EACA,mBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,uBAAA;EACA,yCAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,WAAA;AACF;AACE;EARF;IASI,uBAAA;IACA,mBAAA;EAEF;AACF;;AACA;EACE,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,SAAA;AAEF;;AACA;EACE,aAAA;EACA,YAAA;EACA,gBAAA;EACA,iBAAA;AAEF;;AACA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAEF","sourcesContent":[".conceptCard {\n  border: 1px solid var(--stroke-color);\n  border-radius: 0.625rem;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n  padding: 1rem 1.75rem;\n}\n\n.name {\n  font-weight: 500;\n  font-size: 1.875rem;\n}\n\n.priceContainer {\n  display: flex;\n  align-items: center;\n  gap: 1.75rem;\n  font-size: 1.5rem;\n  line-height: normal;\n}\n\n.price {\n  font-weight: 500;\n}\n\n.details {\n  padding: 1.75rem 1.5rem;\n  border-top: 1px solid var(--stroke-color);\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  gap: 3.5rem;\n\n  @media (min-width: 1024px) {\n    padding: 1.75rem 2.5rem;\n    flex-direction: row;\n  }\n}\n\n.info {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  gap: 1rem;\n}\n\n.meta {\n  display: flex;\n  gap: 1.75rem;\n  font-weight: 500;\n  font-size: 1.5rem;\n}\n\n.actions {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"conceptCard": `ConceptCard_conceptCard__MmNzV`,
	"header": `ConceptCard_header__i2GMY`,
	"name": `ConceptCard_name__t40+-`,
	"priceContainer": `ConceptCard_priceContainer__jjidt`,
	"price": `ConceptCard_price__-CzMv`,
	"details": `ConceptCard_details__GlEvd`,
	"info": `ConceptCard_info__9q8be`,
	"meta": `ConceptCard_meta__6dpQs`,
	"actions": `ConceptCard_actions__wOcrP`
};
export default ___CSS_LOADER_EXPORT___;
