import Icon from '@mdi/react';
import {mdiChevronRight} from '@mdi/js';
import {issueStatuses} from "../../../utils/constants/issue";

function Issue({item}) {
  return (
      <div className="card my-4">
        <div className='card-header d-flex justify-content-between align-items-center'>
          <div className="card-title m-0"><p className='m-0'><span
              className='h6'>{item.concept.name} : </span>{item.key}</p></div>
          <p className="card-subtitle text-muted m-0">
            <span className={item.status === issueStatuses.new && 'text-danger fw-bold'}>
              {issueStatuses.new}
            </span>
            <Icon path={mdiChevronRight} size={0.8}/>
            <span className={item.status === issueStatuses.inProgress && 'text-primary fw-bold'}>
              {issueStatuses.inProgress}
            </span>
            <Icon path={mdiChevronRight} size={0.8}/>
            <span className={item.status === issueStatuses.done && 'text-success fw-bold'}>
              {issueStatuses.done}
            </span>
          </p>
        </div>
        <div className="card-body">
          {item.concept.text}
        </div>
      </div>
  )
}

export default Issue;
