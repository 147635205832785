// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Input_container__uEvQB {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.Input_inputContainer__mOB\\+N {
  position: relative;
  display: flex;
  align-items: center;
}

.Input_defaultContainer__m9MvY {
  width: 100%;
}

.Input_input__OznYk {
  height: 3.125rem;
  border-radius: 0.625rem;
  border: 1px solid;
  width: 100%;
}
.Input_input__OznYk.Input_default__ugMxh {
  background-color: transparent;
  border-color: var(--stroke-color);
  padding: 0.75rem 1.25rem;
}
.Input_input__OznYk.Input_auth__UsH25 {
  padding-left: 1rem;
  background-color: rgba(var(--accent-color), 0.2);
  color: white;
  border-color: white;
  margin-bottom: 0.25rem;
}
.Input_input__OznYk.Input_auth__UsH25::placeholder {
  color: white;
}
.Input_input__OznYk.Input_auth__UsH25:focus {
  box-shadow: 0 0 0 3px var(--focused-color);
  outline: none;
}
.Input_input__OznYk.Input_iconLeft__NkuJ2 {
  padding-left: 2.5rem;
}
.Input_input__OznYk.Input_iconRight__BFwbQ {
  padding-right: 2.5rem;
}
.Input_input__OznYk.Input_error__lV8V1 {
  border-color: var(--fail-color);
}

.Input_icon__lAyCk {
  position: absolute;
}

.Input_iconLeftPosition__b29q- {
  left: 1rem;
}

.Input_iconRightPosition__VTLGe {
  right: 1rem;
}
.Input_iconRightPosition__VTLGe svg {
  width: 1.875rem;
  height: 1.875rem;
}

.Input_label__0OUKF {
  color: var(--text-color);
  font-weight: 500;
  font-size: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/Input.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,gBAAA;EACA,uBAAA;EACA,iBAAA;EACA,WAAA;AACF;AACE;EACE,6BAAA;EACA,iCAAA;EACA,wBAAA;AACJ;AAEE;EACE,kBAAA;EACA,gDAAA;EACA,YAAA;EACA,mBAAA;EACA,sBAAA;AAAJ;AAEI;EACE,YAAA;AAAN;AAGI;EACE,0CAAA;EACA,aAAA;AADN;AAKE;EACE,oBAAA;AAHJ;AAME;EACE,qBAAA;AAJJ;AAOE;EACE,+BAAA;AALJ;;AASA;EACE,kBAAA;AANF;;AASA;EACE,UAAA;AANF;;AASA;EACE,WAAA;AANF;AAQE;EACE,eAAA;EACA,gBAAA;AANJ;;AAUA;EACE,wBAAA;EACA,gBAAA;EACA,iBAAA;AAPF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.inputContainer {\n  position: relative;\n  display: flex;\n  align-items: center;\n}\n\n.defaultContainer {\n  width: 100%;\n}\n\n.input {\n  height: 3.125rem;\n  border-radius: 0.625rem;\n  border: 1px solid;\n  width: 100%;\n\n  &.default {\n    background-color: transparent;\n    border-color: var(--stroke-color);\n    padding: 0.75rem 1.25rem;\n  }\n\n  &.auth {\n    padding-left: 1rem;\n    background-color: rgba(var(--accent-color), 0.2);\n    color: white;\n    border-color: white;\n    margin-bottom: 0.25rem;\n\n    &::placeholder {\n      color: white;\n    }\n\n    &:focus {\n      box-shadow: 0 0 0 3px var(--focused-color);\n      outline: none;\n    }\n  }\n\n  &.iconLeft {\n    padding-left: 2.5rem;\n  }\n\n  &.iconRight {\n    padding-right: 2.5rem;\n  }\n\n  &.error {\n    border-color: var(--fail-color);\n  }\n}\n\n.icon {\n  position: absolute;\n}\n\n.iconLeftPosition {\n  left: 1rem;\n}\n\n.iconRightPosition {\n  right: 1rem;\n\n  svg {\n    width: 1.875rem;\n    height: 1.875rem;\n  }\n}\n\n.label {\n  color: var(--text-color);\n  font-weight: 500;\n  font-size: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Input_container__uEvQB`,
	"inputContainer": `Input_inputContainer__mOB+N`,
	"defaultContainer": `Input_defaultContainer__m9MvY`,
	"input": `Input_input__OznYk`,
	"default": `Input_default__ugMxh`,
	"auth": `Input_auth__UsH25`,
	"iconLeft": `Input_iconLeft__NkuJ2`,
	"iconRight": `Input_iconRight__BFwbQ`,
	"error": `Input_error__lV8V1`,
	"icon": `Input_icon__lAyCk`,
	"iconLeftPosition": `Input_iconLeftPosition__b29q-`,
	"iconRightPosition": `Input_iconRightPosition__VTLGe`,
	"label": `Input_label__0OUKF`
};
export default ___CSS_LOADER_EXPORT___;
