const SolarDollar = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
      className={className}
    >
      <path
        stroke="current"
        strokeWidth="3"
        d="M20 36.666c9.205 0 16.667-7.462 16.667-16.666 0-9.205-7.462-16.667-16.667-16.667-9.205 0-16.667 7.462-16.667 16.667 0 9.204 7.462 16.666 16.667 16.666z"
      ></path>
      <path
        stroke="current"
        strokeLinecap="round"
        strokeWidth="3"
        d="M20 10v20m5-14.167c0-2.3-2.238-4.166-5-4.166s-5 1.866-5 4.166c0 2.3 2.238 4.167 5 4.167s5 1.867 5 4.167-2.238 4.166-5 4.166-5-1.866-5-4.166"
      ></path>
    </svg>
  );
};

export default SolarDollar;
