const Hamburger = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      fill="none"
      viewBox="0 0 50 50"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4.167"
        d="M10.417 35.417h29.166M10.417 25h29.166M10.417 14.584h29.166"
      ></path>
    </svg>
  );
};

export default Hamburger;
