import {configureStore} from "@reduxjs/toolkit";
import userSlice from "./user-slice";
import heygenSlice from "./heygen";

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    heygen: heygenSlice.reducer,
  },
});

export default store;
