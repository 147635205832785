import Modal from "../../../components/ui/Modal";
import styles from "./OrderModal.module.scss";
import {Btn} from "../../../components/ui/Btn";

function OrderModal({close, modalData, submit}) {

  return (
      <Modal close={close}>
        <div className={styles.body}>
          <h1 className={styles.title}>Place an Order</h1>
          <p>You are about to place an order with a concept {modalData?.name}</p>
          <p>Concept:</p>
          <p className={styles.conceptText}>{modalData?.text}</p>
          <p>Price: <span className={styles.accent}>${modalData?.price}</span>. This amount will be blocked on your account until the order is completed.</p>
          <p>Are you sure?</p>
        </div>
        <Btn className={styles.submitBtn} onClick={submit}>Submit</Btn>
      </Modal>
  );
}

export default OrderModal;
