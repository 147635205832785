import {requestGet} from "../../services/api";
import {CONCEPTS_URL, PRODUCT_URL} from "../../api/apiUrls";

export async function productListLoader() {
  const response = await requestGet(PRODUCT_URL);
  if (response.status !== 200) {
    throw new Response(JSON.stringify({message: response.error}), {
      status: response.status,
    });
  } else {
    return response.data;
  }
}

export async function productDetailLoader({request, params}) {
  let product = {};

  if (params.productId) {
    const productResponse = await requestGet(PRODUCT_URL + params.productId + '/');
    if (productResponse.status !== 200) {
      throw new Response(JSON.stringify({message: productResponse.error}), {
        status: productResponse.status,
      });
    } else {
      product = productResponse.data;
    }
  }

  return {product}
}