// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HeyGenTemplatesList_container__roXd3 {
  padding: 3rem 1.25rem;
}
@media (min-width: 992px) {
  .HeyGenTemplatesList_container__roXd3 {
    padding: 3rem 3.5rem;
  }
}

.HeyGenTemplatesList_title__uEbRj {
  font-weight: 700;
  font-size: 3rem;
  font-family: "Play", serif;
  margin-bottom: 3.5rem;
}

.HeyGenTemplatesList_videoList__8H3fR {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
@media (max-width: 1528px) {
  .HeyGenTemplatesList_videoList__8H3fR {
    grid-template-columns: repeat(1, 1fr);
  }
}

.HeyGenTemplatesList_videoContainer__2aSno {
  padding: 1rem;
  display: flex;
  justify-content: center;
  border: 2px solid transparent;
  cursor: pointer;
  transition: border-color 0.3s ease;
  border-radius: 0.625rem;
}

.HeyGenTemplatesList_videoContainer__2aSno:hover {
  border-color: var(--stroke-color);
}

.HeyGenTemplatesList_selected__Xf351 {
  border-color: var(--accent-color);
}

video {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/pages/HeyGen/HeyGenTemplatesList.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;AACE;EAHF;IAII,oBAAA;EAEF;AACF;;AACA;EACE,gBAAA;EACA,eAAA;EACA,0BAAA;EACA,qBAAA;AAEF;;AACA;EACE,aAAA;EACA,qCAAA;EACA,SAAA;AAEF;AAAE;EALF;IAMI,qCAAA;EAGF;AACF;;AAAA;EACE,aAAA;EACA,aAAA;EACA,uBAAA;EACA,6BAAA;EACA,eAAA;EACA,kCAAA;EACA,uBAAA;AAGF;;AAAA;EACE,iCAAA;AAGF;;AAAA;EACE,iCAAA;AAGF;;AAAA;EACE,cAAA;AAGF","sourcesContent":[".container {\n  padding: 3rem 1.25rem;\n\n  @media (min-width: 992px) {\n    padding: 3rem 3.5rem;\n  }\n}\n\n.title {\n  font-weight: 700;\n  font-size: 3rem;\n  font-family: \"Play\", serif;\n  margin-bottom: 3.5rem;\n}\n\n.videoList {\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  gap: 10px;\n\n  @media (max-width: 1528px) {\n    grid-template-columns: repeat(1, 1fr);\n  }\n}\n\n.videoContainer {\n  padding: 1rem;\n  display: flex;\n  justify-content: center;\n  border: 2px solid transparent;\n  cursor: pointer;\n  transition: border-color 0.3s ease;\n  border-radius: 0.625rem;\n}\n\n.videoContainer:hover {\n  border-color: var(--stroke-color);\n}\n\n.selected {\n  border-color: var(--accent-color);\n}\n\nvideo {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `HeyGenTemplatesList_container__roXd3`,
	"title": `HeyGenTemplatesList_title__uEbRj`,
	"videoList": `HeyGenTemplatesList_videoList__8H3fR`,
	"videoContainer": `HeyGenTemplatesList_videoContainer__2aSno`,
	"selected": `HeyGenTemplatesList_selected__Xf351`
};
export default ___CSS_LOADER_EXPORT___;
