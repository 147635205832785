const Person = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        fill="#fff"
        d="M20 20c-1.833 0-3.403-.653-4.708-1.959-1.306-1.306-1.959-2.875-1.959-4.708 0-1.834.653-3.403 1.959-4.709C16.597 7.32 18.167 6.666 20 6.666c1.833 0 3.403.653 4.708 1.958 1.306 1.306 1.959 2.875 1.959 4.709 0 1.833-.653 3.402-1.959 4.708-1.305 1.306-2.875 1.958-4.708 1.958zM6.667 30v-1.334c0-.944.243-1.812.73-2.603a4.883 4.883 0 011.936-1.814 24.711 24.711 0 015.25-1.936 23.018 23.018 0 0110.834 0 24.957 24.957 0 015.25 1.936 4.865 4.865 0 011.938 1.814c.487.792.73 1.66.728 2.603v1.333c0 .917-.326 1.702-.978 2.355a3.202 3.202 0 01-2.355.979H10a3.208 3.208 0 01-2.353-.979A3.216 3.216 0 016.667 30z"
      ></path>
    </svg>
  );
};

export default Person;
