import {useLoaderData, useNavigate} from "react-router-dom";
import styles from './MakeOrder.module.scss';
import {Btn} from "../../components/ui/Btn";
import {generateConcepts} from "../../api/concept";
import {Fragment, useState} from "react";
import Loader from "../../assets/images/loading.gif";
import ConceptCard from "./components/ConceptCard";
import {requestDelete} from "../../services/api";
import {CONCEPTS_URL} from "../../api/apiUrls";
import {RANDOM_STR} from "../../utils/randomStr";
import {createOrder} from "../../api/order";
import {updateUser} from "../../api/user";
import {userActions} from "../../store/user-slice";
import {insufficientFundsError} from "../../utils/constants/user";
import {useDispatch, useSelector} from "react-redux";
import Icon from "@mdi/react";
import {mdiInformationOutline} from "@mdi/js";
import ConceptModal from "./components/ConceptModal";
import DeleteConceptModal from "./components/DeleteConceptModal";
import OrderModal from "./components/OrderModal";

function MakeOrder() {
  const {product, concepts} = useLoaderData();

  const [loading, setLoading] = useState(false);
  const [aiConcepts, setAiConcepts] = useState(concepts);
  const [error, setError] = useState("");

  const [selectedConcept, setSelectedConcept] = useState(null);
  const [openModal, setOpenModal] = useState(""); // expand/delete/order

  const sourceForm = product?.source_forms?.[0] || null;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.user.data);

  const handleGenerateConcepts = async () => {
    setLoading(true);
    if (!sourceForm) {
      setLoading(false);
      return;
    }
    const newConcepts = await generateConcepts(sourceForm.id);
    setAiConcepts([...newConcepts, ...aiConcepts]);
    setLoading(false);
  };

  const deleteConcept = async () => {
    if (!selectedConcept) return;
    await requestDelete(`${CONCEPTS_URL}${selectedConcept.id}/`);
    setAiConcepts((prevConcepts) =>
        prevConcepts.filter((c) => c.id !== selectedConcept.id)
    );
    closeModal();
  };

  const updateUserBalance = async (price) => {
    const newBalance = userData.balance - price;
    const newUserData = await updateUser({balance: newBalance});
    dispatch(userActions.setUserData(newUserData));
  };

  const placeOrder = async () => {
    if (!selectedConcept) return;
    setError("");
    if (selectedConcept.price > userData.balance) {
      setError(insufficientFundsError);
      return;
    }

    const uniqueTitle = sourceForm.title + ' — ' + RANDOM_STR;
    const orderResponse = await createOrder(uniqueTitle, selectedConcept.id);

    if (orderResponse.status === 201) {
      await updateUserBalance(selectedConcept.price);
      navigate("/profile/orders/");
    } else {
      setError("Error placing order")
    }
  }

  const expandConcept = (concept) => {
    setOpenModal("expand");
    setSelectedConcept(concept);
  };

  const openDeleteDialog = (concept) => {
    setOpenModal("delete");
    setSelectedConcept(concept);
  };

  const openOrderDialog = (concept) => {
    setOpenModal("order");
    setSelectedConcept(concept);
  };

  const closeModal = () => {
    setOpenModal("");
    setSelectedConcept(null);
  }

  return (
      <Fragment>
        <section className={styles.makeOrderSection}>
          <div className={styles.makeOrderContainer}>
            <h2 className={styles.title}>Make Order</h2>
            <div className='d-flex justify-content-between'>
              <div className={styles.subtitle}>
                <img src={product.source_forms[0].icon} alt="product icon"
                     className={styles.productIcon}/>
                <h3 className={styles.productName}>{product.source_forms[0].title}</h3>
              </div>
              <Btn className={styles.generateBtn} variant="gradient"
                   onClick={handleGenerateConcepts}>
                Generate
              </Btn>
            </div>
            {error &&
                <div className={styles.error}>
                  <Icon className="me-1" path={mdiInformationOutline} size={1}/>{error}
                </div>
            }
            {!aiConcepts.length && !loading &&
                <div className={styles.noConcepts}>
                  <h3 className={styles.noConceptsText}>
                    click Generate to view concepts
                  </h3>
                </div>
            }
            {loading &&
                <img
                    src={Loader}
                    className={styles.loading}
                    alt="loading"
                />
            }
            {!!aiConcepts.length &&
                <div className={styles.conceptList}>
                  {aiConcepts.map((c) => (
                      <ConceptCard
                          key={c.id}
                          concept={c}
                          placeOrder={openOrderDialog}
                          onSelect={expandConcept}
                          onDelete={openDeleteDialog}
                      />
                  ))}
                </div>
            }
          </div>
        </section>
        {
            openModal === 'expand' && selectedConcept &&
            <ConceptModal close={closeModal} modalData={selectedConcept}/>
        }
        {
            openModal === 'delete' && selectedConcept &&
            <DeleteConceptModal modalData={selectedConcept} close={closeModal}
                                submit={deleteConcept}/>
        }
        {
            openModal === 'order' && selectedConcept &&
            <OrderModal modalData={selectedConcept} close={closeModal}
                                submit={placeOrder}/>
        }
      </Fragment>
  )
}

export default MakeOrder;
