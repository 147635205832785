// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Concepts_conceptsSection__XhD10 {
  padding-top: 6rem;
  padding-bottom: 4rem;
  height: calc(100vh - 60px);
}

.Concepts_conceptsContainer__RnatB {
  padding: 0 1.25rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}
@media (min-width: 1024px) {
  .Concepts_conceptsContainer__RnatB {
    padding: 0 3.5rem;
  }
}

.Concepts_title__WEPWp {
  font-weight: 700;
  font-size: 3rem;
  font-family: "Play", sans-serif;
  margin-bottom: 3.5rem;
}

.Concepts_subtitle__YI8WB {
  margin-bottom: 1.25rem;
  color: var(--text-color);
  font-weight: 500;
  font-size: 2.25rem;
}

.Concepts_controls__FhLeY {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  gap: 1.5rem;
}
@media (min-width: 1024px) {
  .Concepts_controls__FhLeY {
    grid-template-columns: 1fr max-content;
    justify-content: space-between;
    gap: 3.75rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Concepts/Concepts.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,oBAAA;EACA,0BAAA;AACF;;AAEA;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;AACF;AACE;EANF;IAOI,iBAAA;EAEF;AACF;;AACA;EACE,gBAAA;EACA,eAAA;EACA,+BAAA;EACA,qBAAA;AAEF;;AACA;EACE,sBAAA;EACA,wBAAA;EACA,gBAAA;EACA,kBAAA;AAEF;;AACA;EACE,aAAA;EACA,0BAAA;EACA,8BAAA;EACA,WAAA;AAEF;AAAE;EANF;IAOI,sCAAA;IACA,8BAAA;IACA,YAAA;EAGF;AACF","sourcesContent":[".conceptsSection {\n  padding-top: 6rem;\n  padding-bottom: 4rem;\n  height: calc(100vh - 60px);\n}\n\n.conceptsContainer {\n  padding: 0 1.25rem;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n\n  @media (min-width: 1024px) {\n    padding: 0 3.5rem;\n  }\n}\n\n.title {\n  font-weight: 700;\n  font-size: 3rem;\n  font-family: \"Play\", sans-serif;\n  margin-bottom: 3.5rem;\n}\n\n.subtitle {\n  margin-bottom: 1.25rem;\n  color: var(--text-color);\n  font-weight: 500;\n  font-size: 2.25rem;\n}\n\n.controls {\n  display: grid;\n  grid-template-columns: 1fr;\n  justify-content: space-between;\n  gap: 1.5rem;\n\n  @media (min-width: 1024px) {\n    grid-template-columns: 1fr max-content;\n    justify-content: space-between;\n    gap: 3.75rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"conceptsSection": `Concepts_conceptsSection__XhD10`,
	"conceptsContainer": `Concepts_conceptsContainer__RnatB`,
	"title": `Concepts_title__WEPWp`,
	"subtitle": `Concepts_subtitle__YI8WB`,
	"controls": `Concepts_controls__FhLeY`
};
export default ___CSS_LOADER_EXPORT___;
