import {requestGet} from "../../services/api";
import {CONCEPTS_URL, PRODUCT_URL} from "../../api/apiUrls";

export async function productListLoader() {
  const response = await requestGet(PRODUCT_URL);
  if (response.status !== 200) {
    throw new Response(JSON.stringify({message: response.error}), {
      status: response.status,
    });
  } else {
    return response.data;
  }
}

export async function productDetailLoader({request, params}) {
  let product = {};
  let concepts = [];

  if (params.productId) {
    const productResponse = await requestGet(PRODUCT_URL + params.productId + '/');
    if (productResponse.status !== 200) {
      throw new Response(JSON.stringify({message: productResponse.error}), {
        status: productResponse.status,
      });
    } else {
      product = productResponse.data;
    }

    const sourceFormId = product.source_forms.length && product.source_forms[0].id;
    if (sourceFormId) {
      const conceptsResponse = await requestGet(CONCEPTS_URL,
          {
            params: {
              source_form: sourceFormId,
            },
          });
      if (conceptsResponse.status !== 200) {
        throw new Response(JSON.stringify({message: conceptsResponse.error}), {
          status: conceptsResponse.status,
        });
      } else {
        concepts = conceptsResponse.data;
      }
    }
  }

  return {product, concepts}
}