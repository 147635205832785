// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HeyGenFormNavigation_navigation__Rou\\+U {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 600px;
  margin: 36px auto 0 auto;
}

.HeyGenFormNavigation_button__QaCvW {
  max-width: 200px;
}

.HeyGenFormNavigation_navigation__Rou\\+U > :first-child {
  margin-right: auto;
}

.HeyGenFormNavigation_navigation__Rou\\+U > :last-child {
  margin-left: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/HeyGen/components/HeyGenFormNavigation.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,gBAAA;EACA,wBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,iBAAA;AACF","sourcesContent":[".navigation {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  max-width: 600px;\n  margin: 36px auto 0 auto;\n}\n\n.button {\n  max-width: 200px;\n}\n\n.navigation > :first-child {\n  margin-right: auto;\n}\n\n.navigation > :last-child {\n  margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigation": `HeyGenFormNavigation_navigation__Rou+U`,
	"button": `HeyGenFormNavigation_button__QaCvW`
};
export default ___CSS_LOADER_EXPORT___;
