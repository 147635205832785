// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SwitchTheme_switchTheme__LYETk {
  display: none;
}
@media (min-width: 1024px) {
  .SwitchTheme_switchTheme__LYETk {
    display: block;
    margin-top: auto;
    padding-bottom: 1.5rem;
  }
}

.SwitchTheme_switchLabel__qBUs2 {
  display: flex;
  align-items: center;
  position: relative;
  width: max-content;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

.SwitchTheme_switchLabelText__zYecC {
  font-family: "Play";
  font-size: 1.5rem;
  line-height: 1;
  font-weight: 700;
  margin-right: 12px;
}

.SwitchTheme_switchInput__0orpf {
  appearance: none;
  transition: background-color 0.3s;
  cursor: pointer;
  width: 3.5rem;
  height: 1.75rem;
  border-radius: 1rem;
}

.SwitchTheme_greenBackground__AqHy2 {
  background-color: var(--accent-color);
}

.SwitchTheme_accentBackground__Jgfvc {
  background-color: #af31f8;
}

.SwitchTheme_switchToggle__IwltU {
  width: 1.75rem;
  height: 1.75rem;
  position: absolute;
  background-color: #edf2f7;
  border-radius: 50%;
  transform: transition-transform 0.3s;
}

.SwitchTheme_toggleOn__BsMIX {
  right: -0.125rem;
}

.SwitchTheme_toggleOff__R4r4p {
  right: 1.75rem;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/SwitchTheme.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EAHF;IAII,cAAA;IACA,gBAAA;IACA,sBAAA;EAEF;AACF;;AACA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,yBAAA;UAAA,iBAAA;AAEF;;AACA;EACE,mBAAA;EACA,iBAAA;EACA,cAAA;EACA,gBAAA;EACA,kBAAA;AAEF;;AACA;EACE,gBAAA;EACA,iCAAA;EACA,eAAA;EACA,aAAA;EACA,eAAA;EACA,mBAAA;AAEF;;AACA;EACE,qCAAA;AAEF;;AACA;EACE,yBAAA;AAEF;;AACA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,yBAAA;EACA,kBAAA;EACA,oCAAA;AAEF;;AACA;EACE,gBAAA;AAEF;;AACA;EACE,cAAA;AAEF","sourcesContent":[".switchTheme {\n  display: none;\n\n  @media (min-width: 1024px) {\n    display: block;\n    margin-top: auto;\n    padding-bottom: 1.5rem;\n  }\n}\n\n.switchLabel {\n  display: flex;\n  align-items: center;\n  position: relative;\n  width: max-content;\n  cursor: pointer;\n  user-select: none;\n}\n\n.switchLabelText {\n  font-family: \"Play\";\n  font-size: 1.5rem;\n  line-height: 1;\n  font-weight: 700;\n  margin-right: 12px;\n}\n\n.switchInput {\n  appearance: none;\n  transition: background-color 0.3s;\n  cursor: pointer;\n  width: 3.5rem;\n  height: 1.75rem;\n  border-radius: 1rem;\n}\n\n.greenBackground {\n  background-color: var(--accent-color);\n}\n\n.accentBackground {\n  background-color: #af31f8;\n}\n\n.switchToggle {\n  width: 1.75rem;\n  height: 1.75rem;\n  position: absolute;\n  background-color: #edf2f7;\n  border-radius: 50%;\n  transform: transition-transform 0.3s;\n}\n\n.toggleOn {\n  right: -0.125rem;\n}\n\n.toggleOff {\n  right: 1.75rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switchTheme": `SwitchTheme_switchTheme__LYETk`,
	"switchLabel": `SwitchTheme_switchLabel__qBUs2`,
	"switchLabelText": `SwitchTheme_switchLabelText__zYecC`,
	"switchInput": `SwitchTheme_switchInput__0orpf`,
	"greenBackground": `SwitchTheme_greenBackground__AqHy2`,
	"accentBackground": `SwitchTheme_accentBackground__Jgfvc`,
	"switchToggle": `SwitchTheme_switchToggle__IwltU`,
	"toggleOn": `SwitchTheme_toggleOn__BsMIX`,
	"toggleOff": `SwitchTheme_toggleOff__R4r4p`
};
export default ___CSS_LOADER_EXPORT___;
