import {requestGet} from "../../services/api";
import {USER_URL} from "../../api/apiUrls";


export const getUser = async () => {
  try {
    const response = await requestGet(USER_URL);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("Failed to get user", error);
  }
};