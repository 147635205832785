import { ProductForm } from "./components/ProductForm";
import styles from "./NewProduct.module.scss";

function NewProduct() {
  return (
    <section className={styles.newProductSection}>
      <div className={styles.container}>
        <h2 className={styles.title}>New Product</h2>
        <ProductForm />
      </div>
    </section>
  );
}

export default NewProduct;
