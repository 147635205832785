import { useState } from "react";
import { Link, useMatch, useNavigate } from "react-router-dom";
import RegisterForm from "./RegisterForm";
import LoginForm from "./LoginForm";
import Logo from "../../components/icons/Logo";
import styles from "./AuthPage.module.scss";
import {login, register} from "../../api/user";

function AuthPage() {
  const [errMsg, setErrMsg] = useState("");
  const registerMode = useMatch("/register");
  const navigate = useNavigate();

  const authenticate = async (apiData) => {
    const response = registerMode
      ? await register(apiData)
      : await login(apiData);
    if (response.error) {
      if (response.status === 400) {
        setErrMsg("Invalid data provided");
        return;
      }
      setErrMsg("Error occurred");
      return;
    }
    navigate("/profile/products/");
  };

  return (
    <div className={styles.authPage}>
      <div className={styles.logoContainer}>
        <Link className={styles.logoLink} to="/">
          <Logo className={styles.logo} />
          <h1 className={styles.title}>Sociaro AI</h1>
        </Link>
      </div>
      <div className={styles.container}>
        <div className={styles.content}>
          {registerMode ? (
            <>
              <h2 className={styles.header}>Create account</h2>
              <RegisterForm
                authenticate={authenticate}
                setError={setErrMsg}
                error={errMsg}
              />
              <div className={styles.toggleLink}>
                <span>
                  Already have an account?&nbsp;
                  <br className={styles.hideOnLg} />
                  <Link to="/login" className={styles.link}>
                    Sign In
                  </Link>
                </span>
              </div>
            </>
          ) : (
            <>
              <h2 className={styles.header}>Sign in</h2>
              <LoginForm
                authenticate={authenticate}
                setError={setErrMsg}
                error={errMsg}
              />
              <div className={styles.toggleLink}>
                <span>
                  Don`t have an account?&nbsp;
                  <br className={styles.hideOnLg} />
                  <Link to="/register" className={styles.link}>
                    Create an account
                  </Link>
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default AuthPage;
