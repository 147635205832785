import {Link} from "react-router-dom";
import {AccountCircle, Hamburger, Notifications, SolarDollar} from "../icons";
import {useSidebar} from "../../context/SidebarContext";
import styles from "./ProfileNav.module.scss";
import {useSelector} from "react-redux";

export const ProfileNav = () => {
  const {toggleSidebar} = useSidebar();
  const userData = useSelector((state) => state.user.data);
  return (
      <header className={styles.profileHeader}>
        <div>
          <nav>
            <button
                type="button"
                onClick={toggleSidebar}
                className={styles.toggleButton}
            >
              <Hamburger/>
            </button>
            <ul className={styles.navList}>
              <li className={styles.navItem}>
                <Link to="/profile/">
                  <SolarDollar/>
                  <span className={styles.text}>{userData && userData.balance}</span>
                </Link>
              </li>
              <li className={styles.navItem}>
                <Link
                    to="/profile/notifications"
                    className={styles.notificationLink}
                >
                  <Notifications/>
                </Link>
              </li>
              <li className={styles.navItem}>
                <Link to="/profile/account">
                  <AccountCircle/>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>
  );
};
