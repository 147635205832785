const Send = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      fill="currentColor"
      viewBox="0 0 50 50"
    >
      <path
        d="M14.58 21.348l.905 1.583c.578 1.01.865 1.515.865 2.069s-.288 1.058-.865 2.067l-.906 1.585c-2.579 4.515-3.869 6.77-2.864 7.892 1.006 1.118 3.39.077 8.152-2.007l13.075-5.72c3.74-1.636 5.61-2.455 5.61-3.817 0-1.363-1.87-2.181-5.61-3.817l-13.075-5.72c-4.763-2.084-7.146-3.126-8.152-2.007-1.005 1.119.285 3.375 2.864 7.892z"
      ></path>
    </svg>
  );
};

export default Send;
