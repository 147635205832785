const Gear = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        stroke="#1E1E1E"
        strokeWidth="3.333"
        d="M23.333 5.449a2.115 2.115 0 00-2.115-2.115h-2.435c-1.17 0-2.116.946-2.116 2.115 0 .963-.66 1.79-1.559 2.143-.141.057-.283.117-.421.177-.885.383-1.937.266-2.62-.415a2.115 2.115 0 00-2.99 0L7.353 9.077a2.115 2.115 0 000 2.99c.684.683.8 1.733.414 2.62-.061.14-.12.28-.175.421-.354.899-1.18 1.559-2.144 1.559a2.115 2.115 0 00-2.115 2.115v2.436c0 1.169.947 2.116 2.115 2.116.964 0 1.79.66 2.144 1.558.056.142.116.283.175.422.385.884.268 1.936-.414 2.62a2.115 2.115 0 000 2.99l1.724 1.723a2.115 2.115 0 002.99 0c.683-.683 1.733-.8 2.62-.415.138.062.28.12.421.176.899.354 1.559 1.18 1.559 2.144 0 1.168.946 2.115 2.115 2.115h2.436a2.115 2.115 0 002.115-2.115c0-.963.66-1.79 1.559-2.145.141-.055.283-.113.421-.173.885-.387 1.937-.269 2.619.413a2.114 2.114 0 002.991 0l1.724-1.723a2.115 2.115 0 000-2.99c-.684-.684-.8-1.734-.415-2.62.061-.139.12-.28.176-.422.354-.898 1.18-1.558 2.144-1.558a2.115 2.115 0 002.115-2.116v-2.434a2.115 2.115 0 00-2.115-2.115c-.964 0-1.79-.66-2.145-1.559a13.036 13.036 0 00-.175-.421c-.384-.886-.267-1.937.415-2.62a2.115 2.115 0 000-2.99l-1.724-1.724a2.115 2.115 0 00-2.99 0c-.683.684-1.733.8-2.62.415-.14-.061-.28-.12-.421-.176-.899-.356-1.559-1.184-1.559-2.146z"
      ></path>
      <path
        stroke="#1E1E1E"
        strokeWidth="3.333"
        d="M26.667 20a6.667 6.667 0 11-13.334 0 6.667 6.667 0 0113.334 0z"
      ></path>
    </svg>
  );
};

export default Gear;
