// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotificationItem_notificationItem__WEf-p {
  position: relative;
  background-color: white;
  border-radius: 0.625rem;
  padding: 1rem;
  display: flex;
  gap: 1rem;
}
.NotificationItem_notificationItem__WEf-p svg {
  width: 3.75rem;
  height: 3.75rem;
}
@media (min-width: 1024px) {
  .NotificationItem_notificationItem__WEf-p {
    padding: 1.5rem 2.5rem 1.5rem 1.5rem;
  }
}
@media (min-width: 1024px) {
  .NotificationItem_notificationItem__WEf-p {
    gap: 1.75rem;
    align-items: center;
  }
}

.NotificationItem_newIndicator__2XJre {
  position: absolute;
  width: 1rem;
  height: 1rem;
  background-color: var(--accent-color);
  border-radius: 50%;
  right: -0.25rem;
  top: -0.3125rem;
  border: 2px solid var(--background-color);
}

.NotificationItem_fillAccent__mOYsg {
  fill: var(--accent-color);
}

.NotificationItem_fillStroke__1VDrD {
  fill: var(--stroke-color);
}

.NotificationItem_content__kM0JS {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.NotificationItem_header__puLW7 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.NotificationItem_title__LwyaJ {
  font-size: 1.75rem;
  font-weight: 500;
}

.NotificationItem_time__JAkbw {
  font-size: 1.5rem;
  font-weight: 500;
}

.NotificationItem_message__gmWWe {
  font-size: 1.5rem;
}

.NotificationItem_link__wpTmf {
  color: inherit;
  font-weight: 600;
  text-decoration: underline;
  transition: opacity 0.3s;
}
.NotificationItem_link__wpTmf:hover {
  opacity: 0.5;
}`, "",{"version":3,"sources":["webpack://./src/pages/Notifications/NotificationItem.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,uBAAA;EACA,uBAAA;EACA,aAAA;EAWA,aAAA;EACA,SAAA;AATF;AADE;EACE,cAAA;EACA,eAAA;AAGJ;AAAE;EAXF;IAYI,oCAAA;EAGF;AACF;AAEE;EAlBF;IAmBI,YAAA;IACA,mBAAA;EACF;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,qCAAA;EACA,kBAAA;EACA,eAAA;EACA,eAAA;EACA,yCAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,SAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AACF;;AAEA;EACE,kBAAA;EACA,gBAAA;AACF;;AAEA;EACE,iBAAA;EACA,gBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,cAAA;EACA,gBAAA;EACA,0BAAA;EACA,wBAAA;AACF;AACE;EACE,YAAA;AACJ","sourcesContent":[".notificationItem {\n  position: relative;\n  background-color: white;\n  border-radius: 0.625rem;\n  padding: 1rem;\n\n  svg {\n    width: 3.75rem;\n    height: 3.75rem;\n  }\n\n  @media (min-width: 1024px) {\n    padding: 1.5rem 2.5rem 1.5rem 1.5rem;\n  }\n\n  display: flex;\n  gap: 1rem;\n\n  @media (min-width: 1024px) {\n    gap: 1.75rem;\n    align-items: center;\n  }\n}\n\n.newIndicator {\n  position: absolute;\n  width: 1rem;\n  height: 1rem;\n  background-color: var(--accent-color);\n  border-radius: 50%;\n  right: -0.25rem;\n  top: -0.3125rem;\n  border: 2px solid var(--background-color);\n}\n\n.fillAccent {\n  fill: var(--accent-color);\n}\n\n.fillStroke {\n  fill: var(--stroke-color);\n}\n\n.content {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.title {\n  font-size: 1.75rem;\n  font-weight: 500;\n}\n\n.time {\n  font-size: 1.5rem;\n  font-weight: 500;\n}\n\n.message {\n  font-size: 1.5rem;\n}\n\n.link {\n  color: inherit;\n  font-weight: 600;\n  text-decoration: underline;\n  transition: opacity 0.3s;\n\n  &:hover {\n    opacity: 0.5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notificationItem": `NotificationItem_notificationItem__WEf-p`,
	"newIndicator": `NotificationItem_newIndicator__2XJre`,
	"fillAccent": `NotificationItem_fillAccent__mOYsg`,
	"fillStroke": `NotificationItem_fillStroke__1VDrD`,
	"content": `NotificationItem_content__kM0JS`,
	"header": `NotificationItem_header__puLW7`,
	"title": `NotificationItem_title__LwyaJ`,
	"time": `NotificationItem_time__JAkbw`,
	"message": `NotificationItem_message__gmWWe`,
	"link": `NotificationItem_link__wpTmf`
};
export default ___CSS_LOADER_EXPORT___;
