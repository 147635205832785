import Icon from "@mdi/react";
import { Link } from "react-router-dom";
import { cn } from "../../utils/cn";
import styles from "./Btn.module.scss";

export const Btn = ({
  type = "button",
  children,
  variant = "default",
  to,
  disabled,
  onClick,
  icon,
  className,
}) => {
  const classes = cn(
    styles.btn,
    styles[variant],
    { [styles.disabled]: disabled },
    className
  );

  const renderIcon = () => {
    if (typeof icon === "string") {
      return <Icon path={icon} size={"22px"} />;
    } else {
      return icon;
    }
  };

  if (to) {
    return (
      <Link to={to} className={classes}>
        {icon && <Icon path={icon} size={"22px"} />}
        {children}
      </Link>
    );
  }

  return (
    <button
      type={type}
      className={classes}
      onClick={disabled ? null : onClick}
      disabled={disabled}
    >
      {icon && renderIcon()}
      {children}
    </button>
  );
};
