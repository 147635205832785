import Icon from "@mdi/react";
import { mdiInformationOutline } from "@mdi/js";
import { useEffect, useState } from "react";
import { EMAIL_REGEX, PWD_REGEX } from "../../utils/validation";
import { cn } from "../../utils/cn";
import { Btn } from "../../components/ui/Btn";
import Input from "../../components/ui/Input";
import styles from "./LoginForm.module.scss";

function LoginForm({ authenticate, error, setError }) {
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
  }, [pwd]);

  useEffect(() => {
    setError("");
  }, [email, pwd]);

  const validateForm = () => {
    const v1 = EMAIL_REGEX.test(email);
    const v2 = PWD_REGEX.test(pwd);
    if (!v1 || !v2) {
      setError("Invalid Entry");
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    authenticate({ email, password: pwd });
  };

  return (
    <div className={styles.loginFormContainer}>
      <form onSubmit={handleSubmit} className={styles.form}>
        <p
          className={cn(
            styles.errorMessage,
            error ? styles.errorVisible : styles.errorHidden
          )}
          aria-live="assertive"
        >
          {error}
        </p>
        <div className={styles.inputGroup}>
          <Input
            type="email"
            id="emailInput"
            variant="auth"
            autoComplete="off"
            placeholder="Email"
            required
            value={email}
            aria-invalid={validEmail ? "false" : "true"}
            aria-describedby="uidnote"
            onChange={(e) => setEmail(e.target.value.trim())}
            onFocus={() => setEmailFocus(true)}
            onBlur={() => setEmailFocus(false)}
            className={styles.input}
          />
          <small
            id="uidnote"
            className={cn(
              styles.infoMessage,
              emailFocus && !validEmail ? styles.infoVisible : styles.infoHidden
            )}
          >
            <Icon path={mdiInformationOutline} size={0.7} />
            Please enter your email address.
          </small>
        </div>
        <div className={styles.inputGroup}>
          <Input
            type="password"
            id="password"
            variant="auth"
            autoComplete="off"
            placeholder="Password"
            required
            value={pwd}
            aria-invalid={validPwd ? "false" : "true"}
            aria-describedby="pwdnote"
            onChange={(e) => setPwd(e.target.value)}
            onFocus={() => setPwdFocus(true)}
            onBlur={() => setPwdFocus(false)}
            className={styles.input}
          />
          <small
            id="pwdnote"
            className={cn(
              styles.infoMessage,
              pwdFocus && !validPwd ? styles.infoVisible : styles.infoHidden
            )}
          >
            <Icon path={mdiInformationOutline} size={0.7} />
            Please enter your password.
          </small>
        </div>
        <Btn
          type="submit"
          variant="outline-gradient"
          className={styles.submitButton}
          disabled={!validEmail || !validPwd}
        >
          Sign in
        </Btn>
      </form>
    </div>
  );
}

export default LoginForm;
