import React, {useState} from "react";
import {Person, Send} from "../../../components/icons";
import styles from "./CommentsSubmit.module.scss";

export const CommentsSubmit = ({addComment}) => {
  const [newComment, setNewComment] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!newComment.trim().length) return;
    addComment(newComment.trim());
    setNewComment('');
  };

  const disabled = !newComment.trim().length;

  return (
      <form className={styles.commentsSubmit} onSubmit={handleSubmit}>
        <div className={styles.personIcon}>
          <Person/>
        </div>
        <div className={styles.textareaContainer}>
          <div className={styles.textareaWrapper}>
            <textarea className={styles.textarea} value={newComment}
                      onChange={e => setNewComment(e.target.value)} placeholder="Add comment"/>
            <button type="submit" disabled={disabled}
                    className={`${styles.submitButton} ${disabled ? styles.disabled : ''}`}>
              <Send/>
            </button>
          </div>
        </div>
      </form>
  );
};
