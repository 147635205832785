import {requestPatch, requestPost} from "../services/api";
import {REGISTER_URL, TOKEN_URL, USER_URL} from "./apiUrls";
import {setStorageToken} from "../services/auth";

export const register = async (data) => {
  const response = await requestPost(REGISTER_URL, data);
  if (response.status === 201) {
    setStorageToken(response.data.access);
  }
  return response;
}

export const login = async (data) => {
  const response = await requestPost(TOKEN_URL, data);
  if (response.status === 200) {
    setStorageToken(response.data.access);
  }
  return response;
}
export const updateUser = async (data, params) => {
  try {
    const response = await requestPatch(USER_URL, data, params);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("Failed to update user.", error);
  }
};