import DropdownImgTitle from "../../components/ui/DropdownImgTitle";
import {InWorkConcept} from "./components/InWorkConcept";
import styles from "./InWorkPage.module.scss";
import {useLoaderData} from "react-router-dom";
import {useState} from "react";

const InWorkPage = () => {
  const orders = useLoaderData();
  const [selectedOrder, setSelectedOrder] = useState(null);


  let options = [];

  if (orders) {
    orders.map((item) => {
        return options.push({
          value: item.id,
          label: item.name,
        });
    });
  }

  const handleChange = (selected) => {
    const order = orders.find((item) => {
      return item.id === selected.value;
    });
    setSelectedOrder(order);
  };

  return (
    <section className={styles.inWorkSection}>
      <div className={styles.container}>
        <h2 className={styles.heading}>In Work</h2>
        <div className={styles.productSection}>
          <h3 className={styles.productTitle}>Order</h3>
          <DropdownImgTitle options={options} onChange={handleChange} placeholder="---Choose Order---" />
        </div>
        {!!selectedOrder?.issues.length && selectedOrder.issues.map(issue => <InWorkConcept key={issue.id} issueId={issue.id}/>)}
      </div>
    </section>
  );
};

export default InWorkPage;
