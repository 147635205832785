import React from "react";
import styles from "./ScrollToTop.module.scss";
import { ChevronDown } from "./icons";

const ScrollToTop = ({ scrollPosition, scrollbarRef }) => {
  const isVisible = scrollPosition > 300;

  const scrollToTopSmooth = () => {
    if (!scrollbarRef.current) return;

    const scrollDuration = 300;
    const startScrollPosition = scrollbarRef.current.getScrollTop();
    const startTime = performance.now();

    const scrollStep = (currentTime) => {
      const timeElapsed = currentTime - startTime;
      const progress = Math.min(timeElapsed / scrollDuration, 1);
      const easing = easeInOutCubic(progress);

      scrollbarRef.current.scrollTop(startScrollPosition * (1 - easing));

      if (progress < 1) {
        requestAnimationFrame(scrollStep);
      }
    };

    requestAnimationFrame(scrollStep);
  };

  const easeInOutCubic = (t) => {
    return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
  };

  return (
    <div className={styles.scrollToTop}>
      {isVisible && (
        <button onClick={scrollToTopSmooth} className={styles.scrollButton}>
          <ChevronDown />
        </button>
      )}
    </div>
  );
};

export default ScrollToTop;
