import { Link } from "react-router-dom";
import { ChevronDown, Pencil } from "../../../components/icons";
import styles from "./OrderItem.module.scss";

const OrderItem = ({ order }) => {
  return (
    <Link to={`/profile/orders/${order.id}`} className={styles.orderItem}>
      <div>
        <h3 className={styles.orderName}>543 - {order.name}</h3>
        <div className={styles.orderProduct}>Product: {order.product}</div>
      </div>
      <div className={styles.orderDetail}>{order.date}</div>
      <div className={`${styles.orderDetail} ${styles.orderState}`}>
        {order.state}
      </div>
      <div className={styles.orderDetail}>{order.creative}</div>
      <div className={`${styles.orderDetail} ${styles.orderPayment}`}>
        {order.payment}
      </div>
      <div className={styles.orderPrice}>{order.price}</div>
      <div className={styles.actions}>
        <Pencil />
        <ChevronDown className={styles.chevronDown} />
      </div>
    </Link>
  );
};

export default OrderItem;
