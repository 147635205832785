import {requestGet} from "../../services/api";
import {HEY_GEN_VIDEO_TEMPLATES_URL} from "../../api/apiUrls";

export async function videoTemplatesLoader() {
  const response = await requestGet(HEY_GEN_VIDEO_TEMPLATES_URL);
  if (response.status !== 200) {
    throw new Response(JSON.stringify({message: response.error}), {
      status: response.status,
    });
  } else {
    return response.data;
  }
}
