import {requestGet, requestPost} from "../services/api";
import {COMMENTS_URL} from "./apiUrls";

export const fetchComments = async (issueId) => {
  try {
    const response = await requestGet(COMMENTS_URL, {
      params: {
        issue: issueId,
      },
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("Failed to fetch comments", error);
  }
};

export const createComment = async (data, params) => {
  try {
    const response = await requestPost(COMMENTS_URL, data, {params});
    if (response.status === 201) {
      return response.data;
    }
  } catch (error) {
    console.error("Failed to create comment", error);
  }
};


