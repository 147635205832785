import {useEffect, useState} from "react";
import {fetchConcepts, generateConcepts} from "../../../api/concept";
import {createOrder} from "../../../api/order";
import ConceptCard from "./ConceptCard";
import {Btn} from "../../../components/ui/Btn";
import styles from "./ConceptsList.module.scss";
import Loader from "../../../assets/images/loading.gif";
import {requestDelete} from "../../../services/api";
import {CONCEPTS_URL} from "../../../api/apiUrls";
import {useNavigate} from "react-router-dom";
import {RANDOM_STR} from "../../../utils/randomStr";
import {useDispatch, useSelector} from "react-redux";
import {mdiInformationOutline} from "@mdi/js";
import Icon from "@mdi/react";
import {insufficientFundsError} from "../../../utils/constants/user";
import {updateUser} from "../../../api/user";
import {userActions} from "../../../store/user-slice";


function ConceptsList({product}) {
  const [aiConcepts, setAiConcepts] = useState([]);
  const [selectedConcepts, setSelectedConcepts] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const userData = useSelector((state) => state.user.data);
  const dispatch = useDispatch();

  const navigate = useNavigate();


  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchConcepts(product);
      setAiConcepts(data);
    };

    fetchData();
  }, [product]);

  const sourceForm = product?.source_forms?.[0] || null;


  const handleGenerateConcepts = async () => {
    setLoading(true);
    if (!sourceForm) {
      setLoading(false);
      return;
    }
    const newConcepts = await generateConcepts(sourceForm.id);
    setAiConcepts([...newConcepts, ...aiConcepts]);
    setLoading(false);
  };


  const calculateTotalPrice = (selectedConcepts) => {
    const selectedConceptsIds = selectedConcepts.map(concept => concept.id);
    const total = aiConcepts
        .filter((concept) => selectedConceptsIds.includes(concept.id))
        .reduce((sum, concept) => sum + concept.price, 0);

    setTotalPrice(total);
  };

  const selectConcept = (concept) => {
    concept.selected = true;
    const newSelectedConcepts = [...selectedConcepts, concept];
    setSelectedConcepts(newSelectedConcepts);
    calculateTotalPrice(newSelectedConcepts);
  };

  const unselectConcept = (concept) => {
    concept.selected = false;
    setError("");
    const newSelectedConcepts = selectedConcepts.filter(
        (c) => c.id !== concept.id
    );
    setSelectedConcepts(newSelectedConcepts);
    calculateTotalPrice(newSelectedConcepts);
  };

  const deleteConcept = async (concept) => {
    await requestDelete(`${CONCEPTS_URL}${concept.id}/`);
    setAiConcepts((prevConcepts) =>
        prevConcepts.filter((c) => c.id !== concept.id)
    );
    const newSelectedConcepts = selectedConcepts.filter(
        (c) => c.id !== concept.id
    );
    setSelectedConcepts(newSelectedConcepts);
    calculateTotalPrice(newSelectedConcepts);
  };

  const updateUserBalance = async () => {
    const newBalance = userData.balance - totalPrice;
    const newUserData = await updateUser({balance: newBalance});
    dispatch(userActions.setUserData(newUserData));
  };

  const placeOrder = async () => {
    if (!selectedConcepts.length) return;
    if (totalPrice > userData.balance) {
      setError(insufficientFundsError);
      return;
    }
    const selectedConceptsIds = selectedConcepts.map(concept => concept.id);

    const uniqueTitle = sourceForm.title + ' — ' + RANDOM_STR;
    const orderResponse = await createOrder(uniqueTitle, selectedConceptsIds);

    if (orderResponse.status === 201) {
      await updateUserBalance();
    }
    // navigate("/profile/orders/");
    navigate("/profile/in_work/");
  };

  return (
      <div className={styles.conceptsList}>
        <div className={styles.header}>
          {error &&
              <div className={styles.error}>
                <Icon className="me-1" path={mdiInformationOutline} size={1}/>{error}
              </div>
          }
          <Btn variant="gradient" onClick={handleGenerateConcepts}>
            Generate
          </Btn>
          <div className={styles.stats}>
            <div className={styles.stat}>
              Concepts selected:{" "}
              <span className={styles.count}>{selectedConcepts.length}</span>
            </div>
            <div className={styles.stat}>
              Total price: <span className={styles.count}>${totalPrice}</span>
            </div>
            <Btn
                onClick={placeOrder}
                disabled={!selectedConcepts.length || error}
            >
              Make order
            </Btn>
          </div>
        </div>
        {!aiConcepts.length && !loading &&
            <div className={styles.noConcepts}>
              <h3 className={styles.noConceptsText}>
                click Generate to view concepts
              </h3>
            </div>
        }
        {loading &&
            <img
                src={Loader}
                className={styles.loading}
                alt="loading"
            />
        }
        {!!aiConcepts.length &&
            <ul className={styles.conceptList}>
              {aiConcepts.map((c) => (
                  <ConceptCard
                      key={c.id}
                      concept={c}
                      onSelect={selectConcept}
                      onUnselect={unselectConcept}
                      onDelete={deleteConcept}
                  />
              ))}
            </ul>
        }
      </div>
  );
}

export default ConceptsList;
