import HeyGenFormNavigation from "./components/HeyGenFormNavigation";
import {useParams} from "react-router-dom";

function HeyGenVoiceoverGenerate() {
  const { productId } = useParams();

  return (
      <div>
      <div>Voiceover</div>
        <HeyGenFormNavigation productId={productId} prevPath="/" nextPath="/avatar" />
      </div>
  )
}

export default HeyGenVoiceoverGenerate;
