// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductList_productListSection__ty6sH {
  padding-top: 48px;
  padding-bottom: 64px;
}
@media (min-width: 992px) {
  .ProductList_productListSection__ty6sH {
    padding-top: 96px;
  }
}
.ProductList_productListSection__ty6sH .ProductList_container__LKj6j {
  padding-left: 20px;
  padding-right: 20px;
}
@media (min-width: 992px) {
  .ProductList_productListSection__ty6sH .ProductList_container__LKj6j {
    padding-left: 56px;
    padding-right: 56px;
  }
}
.ProductList_productListSection__ty6sH .ProductList_content__BT\\+va {
  display: flex;
  flex-direction: column;
  gap: 65px;
}
.ProductList_productListSection__ty6sH .ProductList_header__g02xR {
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: space-between;
}
@media (min-width: 992px) {
  .ProductList_productListSection__ty6sH .ProductList_header__g02xR {
    flex-direction: row;
    align-items: center;
  }
}
@media (min-width: 1400px) {
  .ProductList_productListSection__ty6sH .ProductList_header__g02xR {
    padding-right: 96px;
  }
}
.ProductList_productListSection__ty6sH .ProductList_title__vFMcs {
  font-weight: bold;
  font-size: 3rem;
  font-family: "Play", sans-serif;
}
.ProductList_productListSection__ty6sH .ProductList_btn__60vze {
  white-space: nowrap;
  margin-left: 14px;
}
.ProductList_productListSection__ty6sH .ProductList_productList__osucQ {
  display: flex;
  flex-direction: column;
  gap: 24px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Products/ProductList.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,oBAAA;AACF;AACE;EAJF;IAKI,iBAAA;EAEF;AACF;AAAE;EACE,kBAAA;EACA,mBAAA;AAEJ;AAAI;EAJF;IAKI,kBAAA;IACA,mBAAA;EAGJ;AACF;AAAE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAEJ;AACE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,8BAAA;AACJ;AACI;EANF;IAOI,mBAAA;IACA,mBAAA;EAEJ;AACF;AAAI;EAXF;IAYI,mBAAA;EAGJ;AACF;AAAE;EACE,iBAAA;EACA,eAAA;EACA,+BAAA;AAEJ;AACE;EACE,mBAAA;EACA,iBAAA;AACJ;AAEE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAAJ","sourcesContent":[".productListSection {\n  padding-top: 48px;\n  padding-bottom: 64px;\n\n  @media (min-width: 992px) {\n    padding-top: 96px;\n  }\n\n  .container {\n    padding-left: 20px;\n    padding-right: 20px;\n\n    @media (min-width: 992px) {\n      padding-left: 56px;\n      padding-right: 56px;\n    }\n  }\n\n  .content {\n    display: flex;\n    flex-direction: column;\n    gap: 65px;\n  }\n\n  .header {\n    display: flex;\n    flex-direction: column;\n    gap: 32px;\n    justify-content: space-between;\n\n    @media (min-width: 992px) {\n      flex-direction: row;\n      align-items: center;\n    }\n\n    @media (min-width: 1400px) {\n      padding-right: 96px;\n    }\n  }\n\n  .title {\n    font-weight: bold;\n    font-size: 3rem;\n    font-family: \"Play\", sans-serif;\n  }\n\n  .btn {\n    white-space: nowrap;\n    margin-left: 14px;\n  }\n\n  .productList {\n    display: flex;\n    flex-direction: column;\n    gap: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"productListSection": `ProductList_productListSection__ty6sH`,
	"container": `ProductList_container__LKj6j`,
	"content": `ProductList_content__BT+va`,
	"header": `ProductList_header__g02xR`,
	"title": `ProductList_title__vFMcs`,
	"btn": `ProductList_btn__60vze`,
	"productList": `ProductList_productList__osucQ`
};
export default ___CSS_LOADER_EXPORT___;
