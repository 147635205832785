// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotificationsPage_notificationsPage__ymE\\+h {
  padding-top: 3rem;
  padding-bottom: 4rem;
}
@media (min-width: 1024px) {
  .NotificationsPage_notificationsPage__ymE\\+h {
    padding-top: 6rem;
  }
}

.NotificationsPage_container__VyYip {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
@media (min-width: 1024px) {
  .NotificationsPage_container__VyYip {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
}

.NotificationsPage_title__XfMSa {
  font-weight: 700;
  font-size: 3rem;
  font-family: "Play", serif;
  margin-bottom: 3.5rem;
}

.NotificationsPage_newNotifications__Zy-mB,
.NotificationsPage_allNotifications__YSA2f {
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
  margin-bottom: 5rem;
}

.NotificationsPage_subtitle__mguRt {
  font-weight: 500;
  font-size: 2.25rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/Notifications/NotificationsPage.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EAMA,oBAAA;AAJF;AAAE;EAHF;IAII,iBAAA;EAGF;AACF;;AAEA;EACE,qBAAA;EACA,sBAAA;AACF;AACE;EAJF;IAKI,oBAAA;IACA,qBAAA;EAEF;AACF;;AACA;EACE,gBAAA;EACA,eAAA;EACA,0BAAA;EACA,qBAAA;AAEF;;AACA;;EAEE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,mBAAA;AAEF;;AACA;EACE,gBAAA;EACA,kBAAA;AAEF","sourcesContent":[".notificationsPage {\n  padding-top: 3rem;\n\n  @media (min-width: 1024px) {\n    padding-top: 6rem;\n  }\n\n  padding-bottom: 4rem;\n}\n\n.container {\n  padding-left: 1.25rem;\n  padding-right: 1.25rem;\n\n  @media (min-width: 1024px) {\n    padding-left: 3.5rem;\n    padding-right: 3.5rem;\n  }\n}\n\n.title {\n  font-weight: 700;\n  font-size: 3rem;\n  font-family: \"Play\", serif;\n  margin-bottom: 3.5rem;\n}\n\n.newNotifications,\n.allNotifications {\n  display: flex;\n  flex-direction: column;\n  gap: 1.75rem;\n  margin-bottom: 5rem;\n}\n\n.subtitle {\n  font-weight: 500;\n  font-size: 2.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notificationsPage": `NotificationsPage_notificationsPage__ymE+h`,
	"container": `NotificationsPage_container__VyYip`,
	"title": `NotificationsPage_title__XfMSa`,
	"newNotifications": `NotificationsPage_newNotifications__Zy-mB`,
	"allNotifications": `NotificationsPage_allNotifications__YSA2f`,
	"subtitle": `NotificationsPage_subtitle__mguRt`
};
export default ___CSS_LOADER_EXPORT___;
