import {createBrowserRouter, Outlet, RouterProvider} from "react-router-dom";
import Error from "./pages/Error/ErrorPage";
import Root from "./components/layout/Root";
import Profile from "./components/layout/Profile";
import Auth from "./pages/Auth/AuthPage";
import ProductList from "./pages/Products/ProductList";
import NewProduct from "./pages/Products/NewProduct";
import ProductDetail from "./pages/Products/ProductDetail";
import OrdersMain from "./pages/Orders/OrdersMain";
import OrderDetail from "./pages/Orders/OrderDetail";
import {productDetailLoader, productListLoader,} from "./pages/Products/loaders";
import {ordersListLoader} from "./pages/Orders/loaders";
import {userLoader} from "./services/loaders";
import Concepts from "./pages/Concepts/Concepts";
import NotificationsPage from "./pages/Notifications/NotificationsPage";
import AccountPage from "./pages/Account/AccountPage";
import InWorkPage from "./pages/InWork/InWorkPage";
import {SidebarProvider} from "./context/SidebarContext";
import {ordersLoader} from "./pages/Concepts/loaders";
import ProductForm from "./pages/Products/components/ProductForm";

const router = createBrowserRouter(
    [
      {
        path: "/",
        element: <Root/>,
        errorElement: <Error/>,
        children: [
          {
            path: "profile",
            element: <Profile/>,
            loader: userLoader,
            children: [
              {
                path: "products",
                element: <Outlet/>,
                children: [
                  {
                    index: true,
                    element: <ProductList/>,
                    loader: productListLoader,
                  },
                  {
                    path: "new",
                    element: <NewProduct/>,
                    loader: productDetailLoader,
                  },
                  {
                    path: ":productId",
                    element: <ProductDetail/>,
                    loader: productDetailLoader,
                  },
                ],
              },
              {
                path: "orders",
                element: <Outlet/>,
                loader: ordersListLoader,
                children: [
                  {
                    index: true,
                    element: <OrdersMain/>,
                  },
                  {
                    path: ":orderId",
                    element: <OrderDetail/>,
                    // loader: orderDetailLoader,
                  },
                ],
              },
              {
                path: "concepts",
                element: <Outlet/>,
                children: [
                  {
                    index: true,
                    element: <Concepts/>,
                    loader: productListLoader,
                  },
                ],
              },
              {
                index: true,
                path: "in_work",
                element: <InWorkPage/>,
                loader: ordersLoader,
              },
              {
                index: true,
                path: "notifications",
                element: <NotificationsPage/>,
              },
              {
                index: true,
                path: "account",
                element: <AccountPage/>,
              },
            ],
          },
          {
            path: "login",
            element: <Auth/>,
          },
          {
            path: "register",
            element: <Auth/>,
          },
        ],
      },
    ],
    {basename: process.env.REACT_APP_PUBLIC_URL || ""}
);

export default function App() {
  return (
      <SidebarProvider>
        <RouterProvider router={router}/>
      </SidebarProvider>
  );
}
