const Cloudy = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="98"
      height="64"
      fill="none"
      viewBox="0 0 98 64"
      className={className}
    >
      <g clipPath="url(#clip0_167_71)">
        <path
          fill="current"
          d="M19.914 30.906h.034a13.47 13.47 0 018.513 3.01l-.026-.02a2.622 2.622 0 003.682-.4l.003-.005a2.607 2.607 0 00-.403-3.677l-.006-.003a18.734 18.734 0 00-5.303-2.976l-.132-.043c3.699-5.408 9.837-8.915 16.795-8.935a20.287 20.287 0 0114.265 5.877 20.227 20.227 0 015.987 14.202v.008a12.866 12.866 0 00-6.13 2.86l.018-.014a2.615 2.615 0 00-.302 3.686l-.003-.003a2.62 2.62 0 003.69.302l-.002.003a7.571 7.571 0 014.914-1.797 7.634 7.634 0 015.382 2.23 7.614 7.614 0 012.235 5.375 7.608 7.608 0 01-2.232 5.376 7.628 7.628 0 01-5.382 2.23h-45.6a13.689 13.689 0 01-9.657-4.002 13.653 13.653 0 01-4.006-9.645 13.647 13.647 0 014.006-9.64 13.684 13.684 0 019.654-3.999h.006zM71.42 6.46a18.664 18.664 0 00-.61 2.792l-.012.106a19.961 19.961 0 003.81 14.73 20.012 20.012 0 0013.092 7.781l.109.012c.838.123 1.802.195 2.784.195h.178-.009c-2.835 5.854-8.561 9.91-15.274 10.33l-.052.003a12.89 12.89 0 00-6.762-4.264l-.09-.02v-.048a25.387 25.387 0 00-2.858-11.715 25.425 25.425 0 00-7.943-9.08l-.08-.055c2.438-5.632 7.507-9.72 13.613-10.75l.104-.017zM19.914 63.428h45.6a12.876 12.876 0 009.088-3.765 12.843 12.843 0 003.768-9.077 12.895 12.895 0 00-.44-3.29l.02.09a23.533 23.533 0 0012.221-5.913A23.483 23.483 0 0097.082 29.8l.032-.16a2.612 2.612 0 00-.63-2.375l.003.003a2.61 2.61 0 00-2.335-.808l.014-.003-1.042.16c-1.548.27-3.129.294-4.684.07l.08.008c-7.21-1.04-12.689-7.17-12.689-14.576 0-.719.052-1.423.15-2.116l-.009.078c.25-1.803.833-3.544 1.719-5.135l-.038.075c.247-.392.382-.843.392-1.305V3.64a2.612 2.612 0 00-2.476-2.61h-.005a23.377 23.377 0 00-13.272 3.32 23.333 23.333 0 00-9.131 10.177l-.06.144a24.946 24.946 0 00-10.005-2.055h-.025a25.627 25.627 0 00-12.865 3.491 25.583 25.583 0 00-9.363 9.478l-.066.126c-.293-.014-.584-.037-.878-.037a18.935 18.935 0 00-13.36 5.535A18.885 18.885 0 00.997 44.55 18.89 18.89 0 006.54 57.895a18.94 18.94 0 0013.36 5.539l.015-.006z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_167_71">
          <path fill="#fff" d="M0 0H98V64H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default Cloudy;
