import { Notificationitem } from "./NotificationItem";
import styles from "./NotificationsPage.module.scss";

const NotificationsPage = () => {
  return (
    <section className={styles.notificationsPage}>
      <div className={styles.container}>
        <h2 className={styles.title}>Notifications</h2>
        <div className={styles.newNotifications}>
          <h3 className={styles.subtitle}>New:</h3>
          <Notificationitem isNew />
        </div>
        <div className={styles.allNotifications}>
          <h3 className={styles.subtitle}>All:</h3>
          <Notificationitem />
        </div>
      </div>
    </section>
  );
};

export default NotificationsPage;
