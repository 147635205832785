// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginForm_loginFormContainer__ay\\+TJ {
  display: flex;
}

.LoginForm_form__cUy\\+J {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LoginForm_errorMessage__AxNPK {
  display: flex;
  justify-content: center;
  color: #e3342f;
}

.LoginForm_errorVisible__OSeQp {
  display: flex;
}

.LoginForm_errorHidden__m3f6P {
  display: none;
}

.LoginForm_inputGroup__bLt0z {
  margin-bottom: 0.75rem;
}

.LoginForm_input__zJj4B {
  width: 21.875rem;
}

.LoginForm_infoMessage__HgB2j {
  color: var(--scroll-color);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.LoginForm_infoVisible__gl9Ee {
  display: flex;
}

.LoginForm_infoHidden__EPrGk {
  display: none;
}

.LoginForm_submitButton__qrUgx {
  margin-top: 6rem;
  width: 16.875rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/Auth/LoginForm.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,cAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,sBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,0BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,gBAAA;EACA,gBAAA;AACF","sourcesContent":[".loginFormContainer {\n  display: flex;\n}\n\n.form {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.errorMessage {\n  display: flex;\n  justify-content: center;\n  color: #e3342f;\n}\n\n.errorVisible {\n  display: flex;\n}\n\n.errorHidden {\n  display: none;\n}\n\n.inputGroup {\n  margin-bottom: 0.75rem;\n}\n\n.input {\n  width: 21.875rem;\n}\n\n.infoMessage {\n  color: var(--scroll-color);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 0.5rem;\n  margin-top: 0.5rem;\n}\n\n.infoVisible {\n  display: flex;\n}\n\n.infoHidden {\n  display: none;\n}\n\n.submitButton {\n  margin-top: 6rem;\n  width: 16.875rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginFormContainer": `LoginForm_loginFormContainer__ay+TJ`,
	"form": `LoginForm_form__cUy+J`,
	"errorMessage": `LoginForm_errorMessage__AxNPK`,
	"errorVisible": `LoginForm_errorVisible__OSeQp`,
	"errorHidden": `LoginForm_errorHidden__m3f6P`,
	"inputGroup": `LoginForm_inputGroup__bLt0z`,
	"input": `LoginForm_input__zJj4B`,
	"infoMessage": `LoginForm_infoMessage__HgB2j`,
	"infoVisible": `LoginForm_infoVisible__gl9Ee`,
	"infoHidden": `LoginForm_infoHidden__EPrGk`,
	"submitButton": `LoginForm_submitButton__qrUgx`
};
export default ___CSS_LOADER_EXPORT___;
