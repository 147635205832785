import { createContext, useState, useContext } from "react";

const SidebarContext = createContext();

export const useSidebar = () => useContext(SidebarContext);

export const SidebarProvider = ({ children }) => {
  const [isMobileVisible, setIsMobileVisible] = useState(false);

  const toggleSidebar = () => {
    setIsMobileVisible((prev) => !prev);
  };

  return (
    <SidebarContext.Provider value={{ isMobileVisible, toggleSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};
