import {createSlice} from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    "id": "",
    "first_name": "",
    "last_name": "",
    "email": "",
    "telegram_username": "",
    "balance": 0
  },
  reducers: {
    setUserData(state, action) {
      state.data = action.payload;
    }
  }
});

export const userActions = userSlice.actions;
export default userSlice;