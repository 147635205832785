// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DeleteConceptModal_title__tzHYv {
  font-weight: 500;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.DeleteConceptModal_body__wjej\\+ {
  font-size: 1.25rem;
}
.DeleteConceptModal_body__wjej\\+ p {
  margin-bottom: 1rem;
}

.DeleteConceptModal_submitBtn__K1lC6 {
  margin-left: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/MakeOrder/components/DeleteConceptModal.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;AACF;AACE;EACE,mBAAA;AACJ;;AAGA;EACE,iBAAA;AAAF","sourcesContent":[".title {\n  font-weight: 500;\n  font-size: 2rem;\n  margin-bottom: 1rem;\n}\n\n.body {\n  font-size: 1.25rem;\n\n  p {\n    margin-bottom: 1rem;\n  }\n}\n\n.submitBtn {\n  margin-left: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `DeleteConceptModal_title__tzHYv`,
	"body": `DeleteConceptModal_body__wjej+`,
	"submitBtn": `DeleteConceptModal_submitBtn__K1lC6`
};
export default ___CSS_LOADER_EXPORT___;
