import {requestGet} from "../../services/api";
import {ORDER_URL} from "../../api/apiUrls";

export const ordersListLoader = async () => {
  const response = await requestGet(ORDER_URL);
  if (response.status !== 200) {
    throw new Response(JSON.stringify({message: response.error}), {
      status: response.status,
    });
  } else {
    return response.data;
  }
};

export const orderDetailLoader = async ({request, params}) => {
  const response = await requestGet(ORDER_URL + params.orderId + '/');
  if (response.status !== 200) {
    throw new Response(JSON.stringify({message: response.error}), {
      status: response.status,
    });
  } else {
    return response.data;
  }
};
