import {CommentsSubmit} from "./CommentsSubmit";
import styles from "./Comments.module.scss";
import Comment from "./Comment";

export const Comments = ({comments, addComment}) => {
  return (
      <div className={styles.comments}>
        <h3 className={styles.heading}>Comments:</h3>
        {comments.map(comment => <Comment key={comment.id} comment={comment}/>)}
        <CommentsSubmit addComment={addComment}/>
      </div>
  );
};
