import {requestGet, requestPatch} from "../services/api";
import {ISSUE_URL} from "./apiUrls";

export const approveIssueStage = async (issueId, data) => {
  try {
    const response = await requestPatch(ISSUE_URL + issueId + '/', data);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("Failed to update issue", error);
  }
};

export const getIssue = async (issueId) => {
  try {
    const response = await requestGet(ISSUE_URL + issueId + '/');
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("Failed to fetch issue", error);
  }
}