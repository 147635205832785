import React from "react";
import styles from "./Input.module.scss";

const Input = ({
  type = "text",
  id,
  value,
  placeholder,
  error,
  valid,
  onChange,
  onFocus,
  onBlur,
  className,
  label,
  variant = "default",
  icon,
  iconPosition = "left",
  ...props
}) => {
  // Apply classes based on conditions
  const inputClasses = [
    styles.input,
    icon && iconPosition === "left" ? styles.iconLeft : "",
    icon && iconPosition === "right" ? styles.iconRight : "",
    error ? styles.error : "",
    variant === "default" ? styles.default : "",
    variant === "auth" ? styles.auth : "",
    // className,
  ].join(" ");

  const containerClasses = [
    styles.container,
    className  // Apply className to the outermost div
  ].join(" ");

  const inputContainerClasses = [
    styles.inputContainer,
    variant === "default" ? styles.defaultContainer : "",
  ].join(" ");

  const iconClasses = [
    styles.icon,
    iconPosition === "left"
      ? styles.iconLeftPosition
      : styles.iconRightPosition,
  ].join(" ");

  return (
    <div className={containerClasses}>
      {label && (
        <label htmlFor={id} className={styles.label}>
          {label}
        </label>
      )}
      <div className={inputContainerClasses}>
        {icon && iconPosition === "left" && (
          <span className={iconClasses}>{icon}</span>
        )}
        <input
          type={type}
          id={id}
          className={inputClasses}
          required
          autoComplete="off"
          placeholder={placeholder}
          value={value}
          aria-invalid={valid ? "false" : "true"}
          aria-describedby={`${id}note`}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          {...props}
        />
        {icon && iconPosition === "right" && (
          <span className={iconClasses}>{icon}</span>
        )}
      </div>
    </div>
  );
};

export default Input;
