// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InWorkPage_inWorkSection__xZtLD {
  padding-top: 3rem;
  padding-bottom: 4rem;
}
@media (min-width: 1024px) {
  .InWorkPage_inWorkSection__xZtLD {
    padding-top: 6rem;
  }
}

.InWorkPage_container__6KkWr {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
@media (min-width: 768px) {
  .InWorkPage_container__6KkWr {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
}

.InWorkPage_heading__5N59- {
  font-weight: 700;
  font-size: 3rem;
  font-family: "Play", serif;
  margin-bottom: 4rem;
}

.InWorkPage_productSection__vBpLu {
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
  margin-bottom: 3.5rem;
}

.InWorkPage_productTitle__tcdLX {
  font-weight: 500;
  font-size: 2.25rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/InWork/InWorkPage.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,oBAAA;AACF;AACE;EAJF;IAKI,iBAAA;EAEF;AACF;;AACA;EACE,qBAAA;EACA,sBAAA;AAEF;AAAE;EAJF;IAKI,oBAAA;IACA,qBAAA;EAGF;AACF;;AAAA;EACE,gBAAA;EACA,eAAA;EACA,0BAAA;EACA,mBAAA;AAGF;;AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,qBAAA;AAGF;;AAAA;EACE,gBAAA;EACA,kBAAA;AAGF","sourcesContent":[".inWorkSection {\n  padding-top: 3rem;\n  padding-bottom: 4rem;\n\n  @media (min-width: 1024px) {\n    padding-top: 6rem;\n  }\n}\n\n.container {\n  padding-left: 1.25rem;\n  padding-right: 1.25rem;\n\n  @media (min-width: 768px) {\n    padding-left: 3.5rem;\n    padding-right: 3.5rem;\n  }\n}\n\n.heading {\n  font-weight: 700;\n  font-size: 3rem;\n  font-family: \"Play\", serif;\n  margin-bottom: 4rem;\n}\n\n.productSection {\n  display: flex;\n  flex-direction: column;\n  gap: 1.75rem;\n  margin-bottom: 3.5rem;\n}\n\n.productTitle {\n  font-weight: 500;\n  font-size: 2.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inWorkSection": `InWorkPage_inWorkSection__xZtLD`,
	"container": `InWorkPage_container__6KkWr`,
	"heading": `InWorkPage_heading__5N59-`,
	"productSection": `InWorkPage_productSection__vBpLu`,
	"productTitle": `InWorkPage_productTitle__tcdLX`
};
export default ___CSS_LOADER_EXPORT___;
