const HorizontalDots = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      fill="none"
      viewBox="0 0 50 50"
    >
      <path
        stroke="#1E1E1E"
        strokeLinejoin="round"
        strokeWidth="7.813"
        d="M25.02 25v.02H25V25h.02zm14.584 0v.02h-.02V25h.02zm-29.166 0v.02h-.021V25h.02z"
      ></path>
    </svg>
  );
};

export default HorizontalDots;
