import React from "react";
import Select from "react-select";
import styles from "./CustomSelect.module.scss";

const CustomSelect = ({
  id,
  options,
  value,
  onChange,
  label,
  placeholder = "Select...",
  className,
  ...props
}) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "3.125rem",
      padding: 0,
      width: "100%",
      borderRadius: "10px",
      borderColor: "#616164",
      boxShadow: "none",
      background: "transparent",
      overflow: "hidden",
      "&:hover": {
        borderColor: "#616164",
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#616164",
      "&:hover": {
        color: "#616164",
      },
    }),
    option: (provided) => ({
      ...provided,
      padding: 0,
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
  };

  return (
    <div className={`${styles.selectContainer} ${className}`}>
      {label && (
        <label htmlFor={id} className={styles.label}>
          {label}
        </label>
      )}
      <Select
        id={id}
        options={options}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        styles={customStyles}
        className={styles.select}
        {...props}
      />
    </div>
  );
};

export default CustomSelect;
