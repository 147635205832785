const Bulb = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      fill="none"
      viewBox="0 0 50 50"
      className={className}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M20.23 42.018a9.782 9.782 0 008.54 0l.32-.155a4.916 4.916 0 002.022-1.819c.49-.784.751-1.692.752-2.62v-1.153c0-3.334 1.904-6.305 3.78-9.056a13.611 13.611 0 00.796-13.982 13.535 13.535 0 00-4.969-5.285 13.455 13.455 0 00-13.942 0 13.535 13.535 0 00-4.969 5.284 13.611 13.611 0 00.796 13.983c1.876 2.752 3.78 5.722 3.78 9.058v1.152c0 .927.26 1.835.751 2.62a4.916 4.916 0 002.023 1.82l.32.153zm6.94-3.328l.32-.158a1.229 1.229 0 00.692-1.11v-1.151c0-.42.02-.83.061-1.233a13.027 13.027 0 01-7.486 0c.04.403.06.814.061 1.233v1.151a1.237 1.237 0 00.692 1.11l.32.155a6.114 6.114 0 005.34.003zM16.395 25.124c.967 1.42 2.187 3.21 3.1 5.296a9.272 9.272 0 0010.01 0c.913-2.086 2.133-3.876 3.102-5.296a9.892 9.892 0 00.577-10.168 9.845 9.845 0 00-3.613-3.842 9.785 9.785 0 00-10.14 0 9.845 9.845 0 00-3.613 3.842 9.899 9.899 0 00.577 10.168z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M24.846 13a1.846 1.846 0 100 3.692 2.461 2.461 0 012.462 2.462 1.846 1.846 0 103.692 0A6.154 6.154 0 0024.846 13z"
      ></path>
    </svg>
  );
};

export default Bulb;
