import HeyGenFormNavigation from "./components/HeyGenFormNavigation";
import {useLoaderData, useParams} from "react-router-dom";
import styles from "./HeyGenTemplatesList.module.scss";
import React, {useState} from "react";
import {orientationFormats} from "./config";
import {requestGet} from "../../services/api";
import {HEY_GEN_VIDEO_TEMPLATES_URL} from "../../api/apiUrls";
import Icon from '@mdi/react';
import {mdiCropLandscape, mdiCropPortrait} from '@mdi/js';
import DropdownImgTitle from "../../components/ui/DropdownImgTitle";
import {useDispatch, useSelector} from "react-redux";
import {heygenActions} from "../../store/heygen";

function HeyGenTemplatesList() {
  const {productId} = useParams();
  const data = useLoaderData();

  const [templates, setTemplates] = useState(data);
  const [format, setFormat] = useState("");

  const dispatch = useDispatch();

  const {key: selectedVideoKey} = useSelector((state) => state.heygen);

  const options = [
    {
      value: orientationFormats.PORTRAIT,
      label: "Portrait",
      icon: <Icon path={mdiCropPortrait} size={1}/>,
    },
    {
      value: orientationFormats.LANDSCAPE,
      label: "Landscape",
      icon: <Icon path={mdiCropLandscape} size={1}/>,
    },
  ];

  const selectFormat = async (option) => {
    setFormat(option.value);
    const response = await requestGet(HEY_GEN_VIDEO_TEMPLATES_URL, {
      params: {
        orientation: option.value,
      },
    });
    setTemplates([...response.data]);
  }

  return (
      <div className={styles.container}>
        <h2 className={styles.title}>Choose Template</h2>
        <DropdownImgTitle
            options={options}
            onChange={selectFormat}
            defaultValue={options.find(option => option.value === format) || options[0]}
            placeholder="Orientation"
            isSearchable={false}
            additionalStyles={{
              width: "200px",
              margin: "0 0 16px 0",
              menu: {width: "200px"},
            }}
        />
        <div className={styles.videoList}>
          {templates.map((template) => (
              <div
                  key={template.id}
                  className={`${styles.videoContainer} ${
                      selectedVideoKey === template.key ? styles.selected : ''
                  }`}
                  onClick={() => dispatch(heygenActions.setKey(template.key))}
              >
                <video
                    width={format === orientationFormats.PORTRAIT ? 360 : 480}
                    height={format === orientationFormats.PORTRAIT ? 480 : 360}
                    controls
                >
                  <source src={template.video} type="video/mp4"/>
                  Your browser does not support the video tag.
                </video>
              </div>
          ))}
        </div>
        <HeyGenFormNavigation
            productId={productId}
            nexDisabled={!selectedVideoKey}
            nextPath="/voiceover"
        />
      </div>
  )
}

export default HeyGenTemplatesList;
