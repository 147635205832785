import {Btn} from "../../../components/ui/Btn";
import {useNavigate} from "react-router-dom";
import styles from './HeyGenFormNavigation.module.scss';

function HeyGenFormNavigation({productId, prevPath, nextPath, nexDisabled}) {
  const navigate = useNavigate();

  return (
      <div className={styles.navigation}>
        {prevPath && (
            <Btn
                onClick={() => navigate(`/profile/heygen/${productId}${prevPath}`)}
                variant="outline-accent"
            >
              Previous
            </Btn>
        )}
        {nextPath && (
            <Btn
                onClick={() => navigate(`/profile/heygen/${productId}${nextPath}`)}
                variant="outline-accent"
                disabled={nexDisabled}
            >
              Next
            </Btn>
        )}
      </div>
  )
}

export default HeyGenFormNavigation;
