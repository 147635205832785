import Modal from "../../../components/ui/Modal";
import styles from "./DeleteConceptModal.module.scss";
import {Btn} from "../../../components/ui/Btn";

function DeleteConceptModal({close, modalData, submit}) {

  return (
      <Modal close={close} maxWidth="800px">
        <div className={styles.body}>
          <h1 className={styles.title}>Delete Concept</h1>
          <p>Are you sure you want to delete the {modalData?.name}? This action is
            irreversible.</p>
          <Btn className={styles.submitBtn} onClick={submit}>Submit</Btn>
        </div>
      </Modal>
  );
}

export default DeleteConceptModal;
