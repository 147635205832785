// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Comments_comments__c9hQP .Comments_heading__uBMC3 {
  font-weight: 500;
  font-size: 2.25rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/InWork/components/Comments.module.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,kBAAA;AAAJ","sourcesContent":[".comments {\n  .heading {\n    font-weight: 500;\n    font-size: 2.25rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comments": `Comments_comments__c9hQP`,
	"heading": `Comments_heading__uBMC3`
};
export default ___CSS_LOADER_EXPORT___;
