const AccountCircle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        fill="#fff"
        d="M20 3.333c9.205 0 16.667 7.462 16.667 16.667 0 9.205-7.462 16.667-16.667 16.667-9.205 0-16.667-7.462-16.667-16.667 0-9.205 7.462-16.666 16.667-16.666zm.267 23.334a11.634 11.634 0 00-8.579 3.76A13.283 13.283 0 0020 33.334a13.283 13.283 0 008.612-3.154 11.633 11.633 0 00-8.345-3.513zm-.267-20A13.333 13.333 0 009.36 28.035a14.966 14.966 0 0110.907-4.701 14.953 14.953 0 0110.603 4.39A13.334 13.334 0 0020 6.667zm0 1.667a6.667 6.667 0 110 13.333 6.667 6.667 0 010-13.334zm0 3.333a3.333 3.333 0 100 6.667 3.333 3.333 0 000-6.667z"
      ></path>
    </svg>
  );
};

export default AccountCircle;
