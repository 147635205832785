import styles from "./IssueStages.module.scss";
import {issueStagesConfig} from "../../../utils/constants/issue";
import Stage from "./Stage";

function IssueStages({issue, approveStage}) {
  return (
      <div className={styles.stagesList}>
        <h3 className={styles.heading}>
          Attachments:
          <a className={styles.link} href={issue.attachment_link}
             target='_blank' rel='noreferrer'>{issue.attachment_link}
          </a>
        </h3>
        <div className={styles.content}>
          {issueStagesConfig.map(item => (
              <Stage
                  key={item.stage}
                  issue={issue}
                  config={item}
                  approve={approveStage}
              />))
          }
        </div>
      </div>
  )
}

export default IssueStages;
