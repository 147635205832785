import {requestGet, requestPost} from "../services/api";
import {CONCEPTS_URL} from "./apiUrls";

export const fetchConcepts = async (product) => {
  try {
    const response = await requestGet(CONCEPTS_URL, {
      params: {
        source_form: product.source_forms[0].id,
      },
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("Failed to fetch concepts", error);
  }
};

export const generateConcepts = async (sourceFormId) => {
  const conceptsNumber = process.env.REACT_APP_CONCEPTS_NUMBER || 3;

  const apiData = {
    "source_form": sourceFormId,
    "concepts_number": conceptsNumber
  }
  try {
    const response = await requestPost(CONCEPTS_URL, apiData);
    if (response.status === 201) {
      return response.data;
    }
  } catch (error) {
    console.error("Failed to generate concepts", error);
  }
}
