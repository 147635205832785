const ChevronFill = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      className={className}
    >
      <path
        fill="#616164"
        fillRule="evenodd"
        d="M5.833 7.5a.833.833 0 00-.589 1.422l4.167 4.167a.833.833 0 001.178 0l4.167-4.167a.834.834 0 00-.59-1.422H5.834z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default ChevronFill;
