import {useEffect} from "react";
import {Outlet, useNavigate} from "react-router-dom";
import {getToken} from "../../services/auth";
import {useDispatch} from "react-redux";
import {getUser} from "./api";
import {userActions} from "../../store/user-slice";

export default function Root() {
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const token = getToken();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await getUser();
        dispatch(userActions.setUserData(userData));
      } catch (error) {
        console.error("Failed to fetch user data", error);
        navigate("/login");
      }
    };

    if (!token) {
      navigate("/login");
    } else {
      fetchUserData();

    }
  }, [token]);

  return (
      <main className="h-100">
        <Outlet/>
      </main>
  );
}
