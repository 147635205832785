import React, {useState} from "react";
import {ChevronFill, Magnifying} from "../../components/icons";
import Input from "../../components/ui/Input";
import styles from "./OrdersMain.module.scss";
import OrderItem from "./components/OrderItem";
import CustomSelect from "../../components/ui/CustomSelect";
import {useLoaderData} from "react-router-dom";

const orders = [
  {
    id: 0,
    name: "Cat simulator",
    product: "Little Kitty 3D",
    date: "05.06.2024",
    state: "in work",
    creative: "5/10",
    payment: "not payed",
    price: "$ 1 000",
  },
];

function OrdersMain() {
  const orders = useLoaderData();

  const [activeFilter, setActiveFilter] = useState(null);

  const handleFilterClick = (filter) => {
    setActiveFilter((prevFilter) => (prevFilter === filter ? null : filter));
  };

  return (
      <section className={styles.ordersSection}>
        <div className={styles.container}>
          <h2 className={styles.title}>My Orders</h2>
          <Input className={styles.search} type="text" iconPosition="right" icon={<Magnifying/>}/>
          {orders && <div className={styles.ordersContainer}>
            <h3 className={styles.ordersTitle}>Orders in progress</h3>
            <CustomSelect
                className={styles.mobileFilters}
                placeholder="Sort by"
            />
            <div className={styles.filters}>
              <div className={styles.orderFilters}>
                <button
                    onClick={() => handleFilterClick("Order name")}
                    className={styles.filterButton}
                >
                  <span>Order name</span>
                  <ChevronFill
                      className={
                        activeFilter === "Order name" ? styles.rotatedChevron : ""
                      }
                  />
                </button>
                <button
                    onClick={() => handleFilterClick("Product")}
                    className={styles.filterButton}
                >
                  <span>Product</span>
                  <ChevronFill
                      className={
                        activeFilter === "Product" ? styles.rotatedChevron : ""
                      }
                  />
                </button>
              </div>
              {["Date", "State", "Creative", "Payment", "Price"].map((filter) => (
                  <button
                      key={filter}
                      onClick={() => handleFilterClick(filter)}
                      className={styles.filterButton}
                  >
                    <span>{filter}</span>
                    <ChevronFill
                        className={
                          activeFilter === filter ? styles.rotatedChevron : ""
                        }
                    />
                  </button>
              ))}
            </div>
            <div className={styles.orderList}>
              {orders.map((order) => (
                  <OrderItem key={order.id} order={order}/>
              ))}
            </div>
          </div>
          }
        </div>
      </section>
  );
}

export default OrdersMain;
