const PlayRounded = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      fill="none"
      viewBox="0 0 50 50"
      className={className}
    >
      <path
        fill="#fff"
        d="M22.57 31.75l8.775-5.625c.42-.27.63-.645.63-1.125s-.21-.855-.63-1.125L22.57 18.25c-.45-.3-.908-.323-1.373-.068-.466.254-.698.652-.697 1.193v11.25c0 .54.233.938.698 1.193.466.256.923.233 1.372-.068zM25 43c-2.49 0-4.83-.473-7.02-1.418-2.19-.946-4.095-2.228-5.715-3.847-1.62-1.619-2.902-3.524-3.847-5.715C7.474 29.829 7.001 27.489 7 25c-.001-2.489.472-4.829 1.418-7.02.947-2.191 2.23-4.096 3.847-5.715 1.618-1.619 3.523-2.901 5.715-3.847C20.172 7.473 22.512 7 25 7c2.488 0 4.828.473 7.02 1.418 2.192.946 4.097 2.228 5.715 3.847 1.618 1.619 2.9 3.524 3.848 5.715S43.003 22.511 43 25c-.004 2.489-.476 4.829-1.418 7.02s-2.225 4.096-3.847 5.715c-1.622 1.619-3.527 2.902-5.715 3.848-2.188.947-4.528 1.42-7.02 1.417zm0-3.6c4.02 0 7.425-1.395 10.215-4.185C38.005 32.425 39.4 29.02 39.4 25s-1.395-7.425-4.185-10.215C32.425 11.995 29.02 10.6 25 10.6s-7.425 1.395-10.215 4.185C11.995 17.575 10.6 20.98 10.6 25s1.395 7.425 4.185 10.215C17.575 38.005 20.98 39.4 25 39.4z"
      ></path>
    </svg>
  );
};

export default PlayRounded;
