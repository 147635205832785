const ListCheck = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      fill="none"
      viewBox="0 0 50 50"
      className={className}
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4.167"
        d="M8.333 35.417h14.584m18.75-6.25L33.333 37.5l-4.166-4.166M8.333 25H31.25M8.333 14.584H31.25"
      ></path>
    </svg>
  );
};

export default ListCheck;
