import { Link } from "react-router-dom";
import { cn } from "../../utils/cn";
import { Message } from "../../components/icons";
import styles from "./NotificationItem.module.scss"; // Import the SCSS module

export const Notificationitem = ({ isNew }) => {
  return (
    <div className={styles.notificationItem}>
      {isNew && <span className={styles.newIndicator}></span>}
      <Message className={cn(isNew ? styles.fillAccent : styles.fillStroke)} />
      <div className={styles.content}>
        <div className={styles.header}>
          <h4 className={styles.title}>AI Creos</h4>
          <span className={styles.time}>2h</span>
        </div>
        <span className={styles.message}>
          Your Order{" "}
          <Link to="/profile/orders/:orderId" className={styles.link}>
            543 - Cat simulator
          </Link>{" "}
          is ready!
        </span>
      </div>
    </div>
  );
};
