import {Btn} from "../../../components/ui/Btn";
import Input from "../../../components/ui/Input";
import {useEffect, useRef, useState} from "react";
import styles from "./ProductFormNew.module.scss";
import {mdiMagnify} from "@mdi/js";
import Icon from "@mdi/react";
import {createProduct, parseProductData, updateProduct} from "../../../api/product";
import {GOOGLE_PLAY_REGEX} from "../../../utils/validation";
import {useNavigate} from "react-router-dom";

export const ProductForm = ({apiProduct = null}) => {
  const [url, setUrl] = useState(apiProduct ? apiProduct.source_forms[0].product_url : "");
  const [urlError, setUrlError] = useState("");
  const [productData, setProductData] = useState(
      apiProduct ? {
        name: apiProduct.source_forms[0].title,
        description: apiProduct.source_forms[0].description,
        icon: apiProduct.source_forms[0].icon,
        tags: apiProduct.source_forms[0].categories.map(c => c.name)
      } : null
  );
  const [audience, setAudience] = useState(apiProduct ? apiProduct.target_audience_ai : "");
  const [country, setCountry] = useState(apiProduct ? apiProduct.recommended_country : "");
  const [mechanics, setMechanics] = useState(apiProduct ? apiProduct.additional_mechanics : "");
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [apiError, setApiError] = useState("");

  const navigate = useNavigate();

  const uiElementsFileInputRef = useRef(null);
  const screenshotsFileInputRef = useRef(null);

  const invalidUrlMsg = "Invalid URL provided";

  useEffect(() => {
    validateUrl(url);
  }, [url]);

  useEffect(() => {
    setSaveDisabled(!url || urlError || !productData);
  }, [url, urlError, productData]);

  const handleFileInputClick = (inputRef) => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const validateUrl = (url) => {
    if (!url.length) {
      setUrlError("");
      return;
    }
    if (GOOGLE_PLAY_REGEX.test(url)) {
      setUrlError("");
    } else {
      setUrlError(invalidUrlMsg);
    }
  };

  const handleChangeUrl = (e) => {
    setUrlError('');
    setApiError('');
    setUrl(e.target.value);
  };

  const handleParseProductData = async () => {
    validateUrl(url);
    if (urlError) return;
    const response = await parseProductData(url);
    if (response.status === 200) {
      setProductData(response.data);
    } else {
      setUrlError(invalidUrlMsg);
    }
  };

  const handleSaveProduct = async () => {
    setApiError("");
    const apiData = {
      google_url: url,
      title: productData.name,
      description: productData.description,
      icon: productData.icon,
      tags: productData.tags,
      recommended_country: country,
      additional_mechanics: mechanics,
      target_audience_ai: audience
    };
    let resp;
    if (!apiProduct) {
      resp = await createProduct(apiData);
    } else {
      resp = await updateProduct(apiData, apiProduct.id)
    }
    if (![200, 201].includes(resp.status)) {
      setApiError(resp.error);
    } else {
      navigate('/profile/products/');
    }
  }

  return (
      <div className={styles.productForm}>
        <h3 className={styles.heading}>Product information:</h3>
        <div className={styles.formGroup}>
          <label className={styles.label}>Link to Google Play:</label>
          <div className={styles.controls}>
            <Input
                type="text"
                placeholder="Link to Google Play..."
                className={styles.inputLink}
                onChange={handleChangeUrl}
                value={url}
            />

            {!apiProduct && <Btn
                variant="outline-accent"
                onClick={handleParseProductData}
                disabled={!url || urlError}
            >
              <Icon path={mdiMagnify} size={1}/>
              Search
            </Btn>}
          </div>
          <p className={styles.errorMsg}>{urlError}</p>
        </div>
        {productData && <div className={styles.titleSection}>
          <h3 className={styles.titleLabel}>Title:</h3>
          <h4 className={styles.title}>{productData.name}</h4>
          <div className={styles.infoGrid}>
            <div className={styles.iconSection}>
              <h4 className={styles.subheading}>Icon:</h4>
              <img src={productData.icon} alt="product icon" className={styles.productIcon}/>
            </div>
            <div className={styles.genreSection}>
              <h4 className={styles.subheading}>Genre:</h4>
              <ul className={styles.genreList}>
                {productData.tags.map((tag, index) => <li key={index}>{tag}</li>)}
              </ul>
            </div>
            <div className={styles.descriptionSection}>
              <h4 className={styles.subheading}>Description:</h4>
              <p className={styles.description}>{productData.description}</p>
            </div>
          </div>
        </div>}
        <div className={styles.formGroup}>
          <label className={styles.label}>Target Audience:</label>
          <textarea
              placeholder="Gender, Age group, Family status, Income, Behavior..."
              className={styles.textarea}
              value={audience}
              onChange={(e) => setAudience(e.target.value.trim())}
          />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>Recommended Country:</label>
          <div className={styles.inputGroup}>
            <Input
                type="text"
                placeholder=""
                value={country}
                onChange={(e) => setCountry(e.target.value.trim())}
            />
          </div>
        </div>
        {/*<div className={styles.materialsSection}>*/}
        {/*  <h3 className={styles.materialsHeading}>Materials:</h3>*/}
        {/*  <CustomSelect label="Type product:" className={styles.select}/>*/}
        {/*</div>*/}
        {/*<div className={styles.resourcesGrid}>*/}
        {/*  <div className={styles.resource}>*/}
        {/*    <h4 className={styles.resourceLabel}>Characters set:</h4>*/}
        {/*    <Link to="#" className={styles.resourceLink}>*/}
        {/*      https://cloud.com/blablabla*/}
        {/*    </Link>*/}
        {/*  </div>*/}
        {/*  <div className={styles.resource}>*/}
        {/*    <h4 className={styles.resourceLabel}>Locations set:</h4>*/}
        {/*    <Link to="#" className={styles.resourceLink}>*/}
        {/*      https://cloud.com/blablabla*/}
        {/*    </Link>*/}
        {/*  </div>*/}
        {/*  <div className={styles.resource}>*/}
        {/*    <h4 className={styles.resourceLabel}>Props set:</h4>*/}
        {/*    <Link to="#" className={styles.resourceLink}>*/}
        {/*      https://cloud.com/blablabla*/}
        {/*    </Link>*/}
        {/*  </div>*/}
        {/*  <div className={styles.uploadSection}>*/}
        {/*    <h4 className={styles.uploadLabel}>UI elements:</h4>*/}
        {/*    <Btn*/}
        {/*        icon={<Upload/>}*/}
        {/*        onClick={() => handleFileInputClick(uiElementsFileInputRef)}*/}
        {/*    >*/}
        {/*      Upload*/}
        {/*    </Btn>*/}
        {/*    <input*/}
        {/*        type="file"*/}
        {/*        ref={uiElementsFileInputRef}*/}
        {/*        className={styles.hiddenInput}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*  <div className={styles.uploadSection}>*/}
        {/*    <h4 className={styles.uploadLabel}>Screenshots (psd):</h4>*/}
        {/*    <Btn*/}
        {/*        icon={<Upload/>}*/}
        {/*        onClick={() => handleFileInputClick(screenshotsFileInputRef)}*/}
        {/*    >*/}
        {/*      Upload*/}
        {/*    </Btn>*/}
        {/*    <input*/}
        {/*        type="file"*/}
        {/*        ref={screenshotsFileInputRef}*/}
        {/*        className={styles.hiddenInput}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className={styles.formGroup}>
          <label className={styles.label}>Additional Mechanics:</label>
          <textarea
              className={styles.textarea}
              value={mechanics}
              onChange={(e) => setMechanics(e.target.value.trim())}
          />
        </div>
        <div className="d-flex">
          <Btn
              type="submit"
              className={styles.submitButton}
              onClick={handleSaveProduct}
              disabled={saveDisabled}
          >
            Save
          </Btn>
          {apiError &&
              <div className={[styles.errorMsg, styles.apiError].join(' ')}>{apiError}</div>}
        </div>
      </div>
  );
};
