const ChevronDown = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
      className={className}
    >
      <path
        fill="current"
        d="M19.958 24.147l12.25-12.25c.417-.417.91-.618 1.48-.605.57.014 1.063.229 1.478.647.417.416.626.91.626 1.48s-.209 1.063-.626 1.478L22.334 27.69a3.332 3.332 0 01-1.125.75c-.416.166-.833.25-1.25.25-.416 0-.833-.084-1.25-.25a3.33 3.33 0 01-1.125-.75L4.75 14.855c-.417-.416-.619-.902-.605-1.458.013-.555.229-1.042.647-1.458.416-.417.91-.625 1.48-.625s1.062.208 1.478.625l12.208 12.208z"
      ></path>
    </svg>
  );
};

export default ChevronDown;
