const Upload = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        d="M3.333 14.166v1.667A1.667 1.667 0 005 17.5h10a1.667 1.667 0 001.667-1.667v-1.667m-10.834-5L10 13.333m0 0l4.167-4.167M10 13.333v-10"
      ></path>
    </svg>
  );
};

export default Upload;
