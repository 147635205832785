const Message = ({ className }) => {
  //   616164
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      fill="none"
      viewBox="0 0 60 60"
      className={className}
    >
      <path
        fill="current"
        d="M45 7.5a10 10 0 0110 10v20a10 10 0 01-10 10H33.19l-11.905 7.142a2.498 2.498 0 01-3.77-1.857L17.5 52.5v-5H15A10 10 0 015.012 38L5 37.5v-20a10 10 0 0110-10h30zM35 30H20a2.5 2.5 0 000 5h15a2.5 2.5 0 000-5zm5-10H20a2.5 2.5 0 000 5h20a2.5 2.5 0 000-5z"
      ></path>
    </svg>
  );
};

export default Message;
