import {mdiPlus} from "@mdi/js";
import {Btn} from "../../../components/ui/Btn";
import styles from "./ConceptCard.module.scss";
import {cn} from "../../../utils/cn";

function ConceptCard({concept, onSelect, onDelete, placeOrder}) {

  return (
      <div className={styles.conceptCard}>
        <div className={cn(styles.header, concept.is_used && styles.used)}>
          <div className='d-flex'>
            <h3 className={styles.name}>{concept.name}</h3>
            {concept.is_used && <p className={styles.usedLabel}>used</p>}
          </div>
          <div className={styles.priceContainer}>
            <span className={styles.price}>${concept.price}</span>
          </div>
        </div>
        <div className={styles.details}>
          <div className={styles.info}>
            <p>{concept.text}</p>
          </div>
          <div className='d-flex mt-3 justify-content-end'>
            <button
                className={styles.subAction}
                onClick={() => navigator.clipboard.writeText(concept.text)}
            >
              Copy
            </button>
            <button
                className={styles.subAction}
                onClick={() => onSelect(concept)}
            >
              Expand
            </button>
          </div>
          <div className={styles.actions}>
            <Btn
                variant="outline"
                onClick={() => onDelete(concept)}
            >
              Delete
            </Btn>
            <Btn
                icon={mdiPlus}
                onClick={() => placeOrder(concept)}
            >
              Make Order
            </Btn>
          </div>
        </div>
      </div>
  );
}

export default ConceptCard;
