const Magnifying = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <path
        fill="#616164"
        fillRule="evenodd"
        d="M18.684 20.674a9.375 9.375 0 111.988-1.988l5.165 5.164a1.404 1.404 0 01.036 2.023 1.406 1.406 0 01-2.023-.035l-5.166-5.164zm1.003-7.549a6.563 6.563 0 11-13.125 0 6.563 6.563 0 0113.125 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Magnifying;
