import {useSelector} from 'react-redux';
import {Creos, Person} from "../../../components/icons";
import moment from "moment/moment";
import {getUserName} from "../../../utils/user";
import styles from "./Comment.module.scss";

function Comment({comment}) {
  const userData = useSelector((state) => state.user.data);

  const userName = getUserName(comment.author);
  return (
      <div className={styles.comment}>
        <div className={styles.iconContainer}>
          {comment.author.id === userData.id ? <Person/> : <Creos/>}
        </div>
        <div className={styles.commentContent}>
          <h4 className={styles.commentAuthor}>
            {userName} - <time>{moment(comment.created_at).format('DD.MM.yyyy HH:mm')}</time>
          </h4>
          <p>
            {comment.text}
          </p>
        </div>
      </div>
  )
}

export default Comment;
