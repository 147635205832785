import axios from 'axios';

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000',
});

client.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
);

export const requestPost = async (path, data, config) => {
  try {
    const response = await client.post(path, data, config);
    if (response) {
      return {data: response.data, status: response.status}
    }
  } catch (err) {
    if (err.response) {
      return {error: err.response.statusText, status: err.response.status}
    }
    return {error: err, status: null}
  }
}

export const requestGet = async (path, config) => {
  try {
    const response = await client.get(path, config);
    if (response) {
      return {data: response.data, status: response.status}
    }
  } catch (err) {
    if (err.response) {
      return {error: err.response.statusText, status: err.response.status}
    }
    return {error: err, status: null}
  }
}

export const requestDelete = async (path, config) => {
  try {
    const response = await client.delete(path, config);
    if (response) {
      return {data: response.data, status: response.status}
    }
  } catch (err) {
    if (err.response) {
      return {error: err.response.statusText, status: err.response.status}
    }
    return {error: err, status: null}
  }
}
export const requestPatch = async (path, data, config) => {
  try {
    const response = await client.patch(path, data, config);
    if (response) {
      return {data: response.data, status: response.status}
    }
  } catch (err) {
    if (err.response) {
      return {error: err.response.statusText, status: err.response.status}
    }
    return {error: err, status: null}
  }
}
export const requestPut = async (path, data, config) => {
  try {
    const response = await client.put(path, data, config);
    if (response) {
      return {data: response.data, status: response.status}
    }
  } catch (err) {
    if (err.response) {
      return {error: err.response.statusText, status: err.response.status}
    }
    return {error: err, status: null}
  }
}