import {ProductsListItem} from "./components/ProductsListItem";
import {mdiPlus} from "@mdi/js";
import {useLoaderData} from "react-router-dom";
import {Btn} from "../../components/ui/Btn";
import styles from "./ProductList.module.scss";

function ProductList() {
  const products = useLoaderData();

  return (
      <section className={styles.productListSection}>
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.header}>
              <h2 className={styles.title}>My Products</h2>
              <div className='d-flex'>
              <Btn
                  to="/profile/products/new"
                  onClick={() => {
                  }}
                  icon={mdiPlus}
                  className={styles.btn}
              >
                Add Product
              </Btn>
              </div>
            </div>
            <div className={styles.productList}>
              {products && products.length > 0 ? (
                  products.map((product) => (
                      <ProductsListItem key={product.id} product={product}/>
                  ))
              ) : (
                  <span>Empty products</span>
              )}
            </div>
          </div>
        </div>
      </section>
  );
}

export default ProductList;
