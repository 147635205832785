// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomSelect_selectContainer__xIPXB {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.CustomSelect_label__98LU7 {
  color: var(--text-color);
  font-weight: 500;
  font-size: 1.5rem;
}

.CustomSelect_select__control__WjJOz {
  height: 50px;
  padding: 0;
  width: 100%;
  border-radius: 0.625rem;
  border-color: #616164;
  box-shadow: none;
  background: transparent;
  overflow: hidden;
}
.CustomSelect_select__control__WjJOz:hover {
  border-color: #616164;
}
.CustomSelect_select__dropdown-indicator__HMBtO {
  color: #616164;
}
.CustomSelect_select__dropdown-indicator__HMBtO:hover {
  color: #616164;
}
.CustomSelect_select__option__nMkk4 {
  padding: 0;
}
.CustomSelect_select__menu-list__MYDpP {
  padding: 0;
}
.CustomSelect_select__indicator-separator__S-oGb {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/CustomSelect.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,wBAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAGE;EACE,YAAA;EACA,UAAA;EACA,WAAA;EACA,uBAAA;EACA,qBAAA;EACA,gBAAA;EACA,uBAAA;EACA,gBAAA;AAAJ;AAEI;EACE,qBAAA;AAAN;AAIE;EACE,cAAA;AAFJ;AAII;EACE,cAAA;AAFN;AAME;EACE,UAAA;AAJJ;AAOE;EACE,UAAA;AALJ;AAQE;EACE,aAAA;AANJ","sourcesContent":[".selectContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.label {\n  color: var(--text-color);\n  font-weight: 500;\n  font-size: 1.5rem;\n}\n\n.select {\n  &__control {\n    height: 50px;\n    padding: 0;\n    width: 100%;\n    border-radius: 0.625rem;\n    border-color: #616164;\n    box-shadow: none;\n    background: transparent;\n    overflow: hidden;\n\n    &:hover {\n      border-color: #616164;\n    }\n  }\n\n  &__dropdown-indicator {\n    color: #616164;\n\n    &:hover {\n      color: #616164;\n    }\n  }\n\n  &__option {\n    padding: 0;\n  }\n\n  &__menu-list {\n    padding: 0;\n  }\n\n  &__indicator-separator {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectContainer": `CustomSelect_selectContainer__xIPXB`,
	"label": `CustomSelect_label__98LU7`,
	"select__control": `CustomSelect_select__control__WjJOz`,
	"select__dropdown-indicator": `CustomSelect_select__dropdown-indicator__HMBtO`,
	"select__option": `CustomSelect_select__option__nMkk4`,
	"select__menu-list": `CustomSelect_select__menu-list__MYDpP`,
	"select__indicator-separator": `CustomSelect_select__indicator-separator__S-oGb`
};
export default ___CSS_LOADER_EXPORT___;
