// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewProduct_newProductSection__1itJt {
  padding-top: 3rem;
  padding-bottom: 4rem;
}
@media (min-width: 1024px) {
  .NewProduct_newProductSection__1itJt {
    padding-top: 6rem;
  }
}

.NewProduct_container__awPpF {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
@media (min-width: 1024px) {
  .NewProduct_container__awPpF {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
}

.NewProduct_title__3PaoT {
  font-weight: 700;
  font-size: 3rem;
  font-family: "Play", serif;
  margin-bottom: 3.5rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/Products/NewProduct.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,oBAAA;AACF;AACE;EAJF;IAKI,iBAAA;EAEF;AACF;;AACA;EACE,qBAAA;EACA,sBAAA;AAEF;AAAE;EAJF;IAKI,oBAAA;IACA,qBAAA;EAGF;AACF;;AAAA;EACE,gBAAA;EACA,eAAA;EACA,0BAAA;EACA,qBAAA;AAGF","sourcesContent":[".newProductSection {\n  padding-top: 3rem;\n  padding-bottom: 4rem;\n\n  @media (min-width: 1024px) {\n    padding-top: 6rem;\n  }\n}\n\n.container {\n  padding-left: 1.25rem;\n  padding-right: 1.25rem;\n\n  @media (min-width: 1024px) {\n    padding-left: 3.5rem;\n    padding-right: 3.5rem;\n  }\n}\n\n.title {\n  font-weight: 700;\n  font-size: 3rem;\n  font-family: \"Play\", serif;\n  margin-bottom: 3.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newProductSection": `NewProduct_newProductSection__1itJt`,
	"container": `NewProduct_container__awPpF`,
	"title": `NewProduct_title__3PaoT`
};
export default ___CSS_LOADER_EXPORT___;
