import {requestGet} from "./api";
import {USER_URL} from "../api/apiUrls";
import { redirect } from "react-router-dom";

export async function userLoader() {
  const response = await requestGet(USER_URL);
  if (response.status !== 200) {
    return redirect("/login");
  } else {
    return response.data;
  }
}