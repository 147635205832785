// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConceptModal_title__-Wd\\+b {
  font-weight: 500;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.ConceptModal_content__73Ek3 {
  font-size: 1rem;
  max-height: 30rem;
  overflow: auto;
  border: 1px solid var(--stroke-color);
  border-radius: 0.625rem;
  padding: 0.5rem 1rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/MakeOrder/components/ConceptModal.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,qCAAA;EACA,uBAAA;EACA,oBAAA;AACF","sourcesContent":[".title {\n  font-weight: 500;\n  font-size: 2rem;\n  margin-bottom: 1rem;\n}\n\n.content {\n  font-size: 1rem;\n  max-height: 30rem;\n  overflow: auto;\n  border: 1px solid var(--stroke-color);\n  border-radius: 0.625rem;\n  padding: .5rem 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `ConceptModal_title__-Wd+b`,
	"content": `ConceptModal_content__73Ek3`
};
export default ___CSS_LOADER_EXPORT___;
