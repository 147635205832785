const Notifications = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        stroke="#fff"
        strokeWidth="3.333"
        d="M12.3 14.455a8.379 8.379 0 0116.656 0l.378 3.399.01.085a11.999 11.999 0 001.61 4.77l.045.076.866 1.444c.787 1.311 1.18 1.966 1.095 2.505a1.5 1.5 0 01-.518.915C32.024 28 31.26 28 29.73 28H11.526c-1.53 0-2.295 0-2.712-.35a1.5 1.5 0 01-.519-.916c-.084-.539.31-1.194 1.095-2.505l.869-1.446.045-.075a12 12 0 001.61-4.768l.008-.086.378-3.399z"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="3.333"
        d="M14.628 28a6 6 0 0012 0"
      ></path>
    </svg>
  );
};

export default Notifications;
