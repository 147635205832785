// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrderModal_title__kGK2I {
  font-weight: 500;
  font-size: 1.875rem;
  margin-bottom: 1rem;
}

.OrderModal_body__oJbH0 {
  font-size: 1.25rem;
  max-height: 30rem;
  padding-right: 2.5rem;
}
.OrderModal_body__oJbH0 p {
  margin-bottom: 0.5rem;
}

.OrderModal_conceptText__oikcA {
  font-size: 1rem;
  border: 1px solid var(--stroke-color);
  border-radius: 0.625rem;
  max-height: 15rem;
  overflow: auto;
  padding: 0.5rem 1rem;
}

.OrderModal_accent__B8ocF {
  font-weight: 700;
}

.OrderModal_submitBtn__CZaim {
  margin-left: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/MakeOrder/components/OrderModal.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,mBAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,iBAAA;EACA,qBAAA;AACF;AACE;EACE,qBAAA;AACJ;;AAGA;EACE,eAAA;EACA,qCAAA;EACA,uBAAA;EACA,iBAAA;EACA,cAAA;EACA,oBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,iBAAA;AAAF","sourcesContent":[".title {\n  font-weight: 500;\n  font-size: 1.875rem;\n  margin-bottom: 1rem;\n}\n\n.body {\n  font-size: 1.25rem;\n  max-height: 30rem;\n  padding-right: 2.5rem;\n\n  p {\n    margin-bottom: 0.5rem;\n  }\n}\n\n.conceptText {\n  font-size: 1rem;\n  border: 1px solid var(--stroke-color);\n  border-radius: 0.625rem;\n  max-height: 15rem;\n  overflow: auto;\n  padding: .5rem 1rem;\n}\n\n.accent {\n  font-weight: 700;\n}\n\n.submitBtn {\n  margin-left: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `OrderModal_title__kGK2I`,
	"body": `OrderModal_body__oJbH0`,
	"conceptText": `OrderModal_conceptText__oikcA`,
	"accent": `OrderModal_accent__B8ocF`,
	"submitBtn": `OrderModal_submitBtn__CZaim`
};
export default ___CSS_LOADER_EXPORT___;
