const Creos = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="27"
      fill="none"
      viewBox="0 0 39 27"
    >
      <g clipPath="url(#clip0_170_88)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M2.229 19.697h1.718C4.178 23.769 7.577 27 11.735 27h15.6c4.158 0 7.556-3.231 7.787-7.303h1.65A2.22 2.22 0 0039 17.484V9.516a2.22 2.22 0 00-2.229-2.213h-1.649C34.892 3.231 31.492 0 27.334 0h-15.6C7.577 0 4.179 3.231 3.948 7.303H2.23A2.22 2.22 0 000 9.516v7.968a2.22 2.22 0 002.229 2.213z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#1E1E1E"
          d="M26.52 4.426H12.48c-3.077 0-5.571 2.477-5.571 5.533v7.082c0 3.055 2.494 5.532 5.571 5.532h14.04c3.077 0 5.571-2.477 5.571-5.532V9.959c0-3.056-2.494-5.533-5.571-5.533z"
        ></path>
        <path
          fill="#fff"
          d="M23.4 16.82s-1.019 1.992-3.566 1.992c-2.547 0-3.565-1.992-3.565-1.992"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M23.4 16.82s-1.019 1.992-3.566 1.992c-2.547 0-3.565-1.992-3.565-1.992"
        ></path>
        <path
          fill="#fff"
          d="M12.814 12.394a2.11 2.11 0 002.117-2.102 2.11 2.11 0 00-2.117-2.103 2.11 2.11 0 00-2.117 2.103 2.11 2.11 0 002.117 2.102zM26.409 12.394a2.11 2.11 0 002.117-2.102 2.11 2.11 0 00-2.117-2.103 2.11 2.11 0 00-2.118 2.103 2.11 2.11 0 002.118 2.102z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_170_88">
          <path fill="#fff" d="M0 0H39V27H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default Creos;
