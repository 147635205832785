const LogoutFill = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <g clipPath="url(#clip0_125_461)">
        <path
          fill="#1E1E1E"
          d="M20 4.166a2.5 2.5 0 010 5h-8.333a.833.833 0 00-.834.833v20a.833.833 0 00.834.834h7.5a2.5 2.5 0 110 5h-7.5a5.833 5.833 0 01-5.834-5.834V10a5.833 5.833 0 015.834-5.833H20zm10.1 9.35l4.715 4.717a2.5 2.5 0 010 3.533l-4.713 4.717a2.5 2.5 0 11-3.537-3.537l.447-.447H20a2.5 2.5 0 110-5h7.012l-.447-.446a2.5 2.5 0 013.537-3.535l-.002-.002z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_125_461">
          <path fill="#fff" d="M0 0H40V40H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoutFill;
