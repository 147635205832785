const StarRounded = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      fill="none"
      viewBox="0 0 50 50"
      className={className}
    >
      <path
        fill="#fff"
        d="M25 35.99l-8.646 5.208a1.893 1.893 0 01-1.198.312 2.014 2.014 0 01-1.094-.416 2.501 2.501 0 01-.729-.91c-.173-.365-.208-.773-.104-1.226l2.292-9.844L7.865 22.5c-.348-.313-.564-.669-.65-1.069-.087-.4-.06-.79.077-1.17.137-.381.346-.694.625-.938.279-.245.66-.4 1.146-.469l10.104-.885 3.906-9.271c.174-.417.443-.73.808-.938A2.233 2.233 0 0125 7.448c.38 0 .753.104 1.119.312.365.209.634.521.808.938l3.906 9.27 10.105.886c.486.07.868.226 1.145.469.278.243.486.555.625.937.14.382.166.773.08 1.173-.087.4-.304.756-.653 1.067l-7.656 6.614 2.292 9.844c.104.452.07.86-.104 1.225a2.478 2.478 0 01-.73.91c-.312.242-.677.381-1.093.417a1.875 1.875 0 01-1.198-.312L25 35.989z"
      ></path>
    </svg>
  );
};

export default StarRounded;
