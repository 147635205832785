import {Link, useNavigate} from "react-router-dom";
import {
  KeyFill,
  LogoutFill,
  MessageFill,
  SolarDollar,
} from "../../components/icons";
import { Btn } from "../../components/ui/Btn";
import { removeToken } from "../../services/auth";
import Input from "../../components/ui/Input";
import styles from "./AccountPage.module.scss"; // Import the SCSS module

const AccountPage = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    removeToken();
    navigate("/login/");
  };

  return (
    <section className={styles.accountPage}>
      <div className={styles.container}>
        <h2 className={styles.heading}>My Account</h2>
        <div className={styles.personalInfo}>
          <h3 className={styles.subheading}>Personal information:</h3>
          <form className={styles.form}>
            <Input type="text" label="Name:" />
            <Input type="text" label="Last Name:" />
            <Input type="email" label="Email:" />
            <Input type="text" label="Username Telegram:" />
            <Btn type="submit" className={styles.saveButton}>
              Save
            </Btn>
          </form>
        </div>
        <div className={styles.balanceSection}>
          <h3 className={styles.subheading}>Balance:</h3>
          <div className={styles.balanceDetails}>
            <span className={styles.balanceAmount}>
              <SolarDollar className={styles.solarDollarIcon} />
              <span className={styles.amountText}>1 000</span>
            </span>
            <Btn as="link" className={styles.fillUpButton}>
              Fill up
            </Btn>
          </div>
        </div>
        <div className={styles.links}>
          <Link to="/" className={styles.link}>
            <KeyFill />
            <span>Change password</span>
          </Link>
          <Link to="/support" className={styles.link}>
            <MessageFill />
            <span>Write to support</span>
          </Link>
          <button type="button" className={styles.link} onClick={handleLogout}>
            <LogoutFill />
            <span>Log out</span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default AccountPage;
