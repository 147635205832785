import React, {useState} from "react";
import {Link, NavLink} from "react-router-dom";
import {cn} from "../../utils/cn";
import Logo from "../icons/Logo";
import {Hamburger, ListCheck, PlayRounded, StarRounded,} from "../icons";
import Bulb from "../icons/Bulb";
import {SwitchTheme} from "../ui/SwitchTheme";
import {useSidebar} from "../../context/SidebarContext";
import styles from "./Sidebar.module.scss";

const navItems = [
  {
    id: 0,
    name: "Products",
    path: "/profile/products",
    icon: <StarRounded className={styles.navItemIcon}/>,
  },
  {
    id: 1,
    name: "Orders",
    path: "/profile/orders",
    icon: <ListCheck className={styles.navItemIcon}/>,
  },
  {
    id: 2,
    name: "Concepts",
    path: "/profile/concepts",
    icon: <Bulb className={styles.navItemIcon}/>,
  },
  {
    id: 3,
    name: "In Work",
    path: "/profile/in_work",
    icon: <PlayRounded className={styles.navItemIcon}/>,
  }
];

export default function Sidebar() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const {isMobileVisible, toggleSidebar} = useSidebar();

  const handleToggle = () => {
    if (window.innerWidth < 1024) {
      toggleSidebar();
    } else {
      setIsCollapsed((prev) => !prev);
    }
  };

  return (
      <div className="h-full">
        <div
            className={cn(
                styles.sidebar,
                isMobileVisible ? styles.mobileVisible : styles.mobileHidden,
                isCollapsed && styles.collapsed
            )}
        >
          <Link to="/profile/concepts/" className={styles.logoLink}>
            <Logo
                width={isCollapsed ? "40" : "64"}
                height={isCollapsed ? "28" : "44"}
                className={styles.logo}
            />
            {!isCollapsed && <h1 className={styles.brandTitle}>AI Creos</h1>}
          </Link>
          <div className={styles.sidebarHeader}>
            {/*{!isCollapsed && <h2 className={styles.brandName}>Brand</h2>}*/}
            <button className={styles.toggleBtn} onClick={handleToggle}>
              <Hamburger/>
            </button>
          </div>
          <nav className={styles.nav}>
            {navItems.map((item) => (
                <NavLink
                    key={item.id}
                    to={item.path}
                    onClick={toggleSidebar}
                    className={({isActive}) =>
                        cn(
                            styles.navItem,
                            isCollapsed && "collapsed",
                            isActive ? "active" : ""
                        )
                    }
                >
                  {item.icon}
                  {!isCollapsed && (
                      <span className={styles.navItemText}>{item.name}</span>
                  )}
                </NavLink>
            ))}
          </nav>
          {/*{!isCollapsed && <SwitchTheme/>}*/}
        </div>
      </div>
  );
}
