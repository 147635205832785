const MessageFill = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        fill="#1E1E1E"
        d="M32.9 5H8.1C6.39 5 5 6.386 5 8.088v18.624A3.098 3.098 0 008.1 29.8h4.65V36l9.844-6.2H32.9c1.71 0 3.1-1.386 3.1-3.088V8.088A3.097 3.097 0 0032.9 5z"
      ></path>
    </svg>
  );
};

export default MessageFill;
