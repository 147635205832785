import React from "react";
import { cn } from "../../utils/cn";

function Logo({ width = "87", height = "60", className }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 87 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_102_5)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.97143 43.7705H8.80515C9.32043 52.8192 16.9012 60 26.1772 60H60.9771C70.2532 60 77.8342 52.8192 78.3492 43.7705H82.0286C84.7743 43.7705 87 41.5686 87 38.8525V21.1475C87 18.4314 84.7743 16.2295 82.0286 16.2295H78.3492C77.8342 7.18062 70.2532 0 60.9771 0H26.1772C16.9012 0 9.32043 7.18062 8.80515 16.2295H4.97143C2.22578 16.2295 0 18.4314 0 21.1475V38.8525C0 41.5686 2.22578 43.7705 4.97143 43.7705Z"
          fill="current"
        />
        <path
          d="M59.16 9.83618H27.84C20.9759 9.83618 15.4114 15.3409 15.4114 22.1313V37.869C15.4114 44.6594 20.9759 50.1641 27.84 50.1641H59.16C66.0241 50.1641 71.5886 44.6594 71.5886 37.869V22.1313C71.5886 15.3409 66.0241 9.83618 59.16 9.83618Z"
          fill="#021972"
        />
        <path
          d="M52.2 37.377C52.2 37.377 49.9276 41.8032 44.2457 41.8032C38.5641 41.8032 36.2914 37.377 36.2914 37.377"
          stroke="white"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.5857 27.541C31.1941 27.541 33.3086 25.4493 33.3086 22.8689C33.3086 20.2886 31.1941 18.1968 28.5857 18.1968C25.9773 18.1968 23.8629 20.2886 23.8629 22.8689C23.8629 25.4493 25.9773 27.541 28.5857 27.541Z"
          fill="#30EDF9"
        />
        <path
          d="M58.9115 27.541C61.5198 27.541 63.6343 25.4493 63.6343 22.8689C63.6343 20.2886 61.5198 18.1968 58.9115 18.1968C56.3031 18.1968 54.1886 20.2886 54.1886 22.8689C54.1886 25.4493 56.3031 27.541 58.9115 27.541Z"
          fill="#30EDF9"
        />
      </g>
      <defs>
        <clipPath id="clip0_102_5">
          <rect width="87" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Logo;
