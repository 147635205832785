export const issueStatuses = {
  new: 'New',
  inProgress: 'In Progress',
  done: 'Done'
};

export const issueStages = {
  STATIC: "Static",
  STATIC_APPROVED: "Static Approved",
  ANIMATIC: "Animatic",
  ANIMATIC_APPROVED: "Animatic Approved",
  FINAL_RENDER: "Final Render",
  FINAL_RENDER_APPROVED: "Final Render Approved"
};

export const issueStagesConfig = [
  {
    stage: "STATIC",
    hint: 'Check out this development stage! Follow the link in the Attachments, open the folder with the stage name, view the files. If everything is good, click the "Approve stage" button, if you have comments, send your comments below.',
    next: ['STATIC_APPROVED', 'ANIMATIC', 'ANIMATIC_APPROVED', 'FINAL_RENDER', 'FINAL_RENDER_APPROVED'],
    previous: [],
    apiApprove: "STATIC_APPROVED"
  },
  {
    stage: "ANIMATIC",
    hint: 'Check out this development stage! Follow the link in the Attachments, open the folder with the stage name, view the files. If everything is good, click the "Approve stage" button, if you have comments, send your comments below.',
    next: ['ANIMATIC_APPROVED', 'FINAL_RENDER', 'FINAL_RENDER_APPROVED'],
    previous: ['STATIC', 'STATIC_APPROVED'],
    apiApprove: "ANIMATIC_APPROVED"
  },
  {
    stage: "FINAL_RENDER",
    hint: 'Check out this development stage! Follow the link in the Attachments, open the folder with the stage name, view the files. If everything is good, click the "Approve stage" button, if you have comments, send your comments below.',
    next: ['FINAL_RENDER_APPROVED'],
    previous: ['STATIC', 'STATIC_APPROVED', 'ANIMATIC', 'ANIMATIC_APPROVED'],
    apiApprove: "FINAL_RENDER_APPROVED"
  }
];

// eslint-disable-next-line no-template-curly-in-string
export const issueStageApprovedMessage = "Stage {stage} has been approved.";