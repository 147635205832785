// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Profile_profileContainer__J9iEg {
  display: flex;
  height: 100vh;
  width: 100%;
}

.Profile_profileContent__PE9QQ {
  background: var(--background-color);
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.Profile_horizontalTrack__jJd\\+m {
  display: none;
}

.Profile_verticalTrack__5A\\+8d {
  position: absolute;
  width: 10px;
  top: 16px;
  bottom: 16px;
  right: 20px;
  border-radius: 8px;
  visibility: visible;
  background: #fff;
  display: none;
}
@media (min-width: 768px) {
  .Profile_verticalTrack__5A\\+8d {
    display: block;
  }
}

.Profile_verticalThumb__zv0Jj {
  background: var(--scroll-color);
  border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/layout/Profile.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,WAAA;AACF;;AAEA;EACE,mCAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,SAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,aAAA;AACF;AACE;EAXF;IAYI,cAAA;EAEF;AACF;;AACA;EACE,+BAAA;EACA,kBAAA;AAEF","sourcesContent":[".profileContainer {\n  display: flex;\n  height: 100vh;\n  width: 100%;\n}\n\n.profileContent {\n  background: var(--background-color);\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n}\n\n.horizontalTrack {\n  display: none;\n}\n\n.verticalTrack {\n  position: absolute;\n  width: 10px;\n  top: 16px;\n  bottom: 16px;\n  right: 20px;\n  border-radius: 8px;\n  visibility: visible;\n  background: #fff;\n  display: none;\n\n  @media (min-width: 768px) {\n    display: block;\n  }\n}\n\n.verticalThumb {\n  background: var(--scroll-color);\n  border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profileContainer": `Profile_profileContainer__J9iEg`,
	"profileContent": `Profile_profileContent__PE9QQ`,
	"horizontalTrack": `Profile_horizontalTrack__jJd+m`,
	"verticalTrack": `Profile_verticalTrack__5A+8d`,
	"verticalThumb": `Profile_verticalThumb__zv0Jj`
};
export default ___CSS_LOADER_EXPORT___;
