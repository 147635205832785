// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorElement_pageWrap__oEeqL {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: var(--background-color);
}

.ErrorElement_container__HZ0mU {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.ErrorElement_title__N0C1R {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.ErrorElement_statusCode__UmHnV {
  font-size: 6rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: var(--accent-color);
}

.ErrorElement_statusText__rsYcB {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
}

.ErrorElement_backLink__73-ip {
  font-size: 1rem;
  text-decoration: underline;
  color: var(--accent-color);
  transition: opacity 0.3s;
}
.ErrorElement_backLink__73-ip:hover {
  opacity: 0.7;
}`, "",{"version":3,"sources":["webpack://./src/pages/Error/ErrorElement.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,aAAA;EACA,WAAA;EACA,yCAAA;AACF;;AAEA;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;AACF;;AAEA;EACE,iBAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,0BAAA;AACF;;AAEA;EACE,kBAAA;EACA,qBAAA;AACF;;AAEA;EACE,eAAA;EACA,0BAAA;EACA,0BAAA;EACA,wBAAA;AACF;AACE;EACE,YAAA;AACJ","sourcesContent":[".pageWrap {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  height: 100vh;\n  width: 100%;\n  background-color: var(--background-color);\n}\n\n.container {\n  text-align: center;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n}\n\n.title {\n  font-size: 1.5rem;\n  font-weight: 500;\n  margin-bottom: 1rem;\n}\n\n.statusCode {\n  font-size: 6rem;\n  font-weight: 700;\n  margin-bottom: 1rem;\n  color: var(--accent-color);\n}\n\n.statusText {\n  font-size: 1.25rem;\n  margin-bottom: 1.5rem;\n}\n\n.backLink {\n  font-size: 1rem;\n  text-decoration: underline;\n  color: var(--accent-color);\n  transition: opacity 0.3s;\n\n  &:hover {\n    opacity: 0.7;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageWrap": `ErrorElement_pageWrap__oEeqL`,
	"container": `ErrorElement_container__HZ0mU`,
	"title": `ErrorElement_title__N0C1R`,
	"statusCode": `ErrorElement_statusCode__UmHnV`,
	"statusText": `ErrorElement_statusText__rsYcB`,
	"backLink": `ErrorElement_backLink__73-ip`
};
export default ___CSS_LOADER_EXPORT___;
