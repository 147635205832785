import React from "react";
import Select, {components} from "react-select";
import {ChevronDown} from "../icons";
import styles from "./DropdownImgTitle.module.scss";

const CustomDropdownIndicator = (props) => {
  return (
      <components.DropdownIndicator {...props}>
        <ChevronDown className={styles.selectIcon}/>
      </components.DropdownIndicator>
  );
};

const DropdownImgTitle = ({
                            options,
                            placeholder,
                            onChange,
                            defaultValue,
                            isSearchable = true,
                            additionalStyles = {},
                          }) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: additionalStyles.height || "3.125rem",
      padding: additionalStyles.padding || 0,
      margin: additionalStyles.margin || 0,
      maxWidth: additionalStyles.maxWidth || "65.5rem",
      width: additionalStyles.width || "100%",
      borderRadius: "10px",
      borderColor: "#616164",
      boxShadow: "none",
      background: "transparent",
      overflow: "hidden",
      "&:hover": {
        borderColor: "#616164",
      },
      ...additionalStyles.control,
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "1rem",
      ...additionalStyles.placeholder,
    }),
    menu: (provided) => ({
      ...provided,
      width: "100%",
      maxWidth: additionalStyles.menu.maxWidth || "65.5rem",
      ...additionalStyles.menu,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0,
      marginRight: "20px",
      ...additionalStyles.dropdownIndicator,
    }),
    option: (provided) => ({
      ...provided,
      padding: "0.5rem",
      ...additionalStyles.option,
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      ...additionalStyles.menuList,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
      ...additionalStyles.indicatorSeparator,
    }),
  };

  const formatOptionLabel = ({label, image, icon}) => {
    return (
        <div className="flex items-center gap-x-7">
          {image && (
              <img
                  src={image}
                  alt={label}
                  width="36"
                  height="36"
                  className="me-2"
              />
          )}
          {icon && <span className="me-2">{icon}</span>}
          <span className="font-medium text-[24px]/none">{label}</span>
        </div>
    );
  };

  return (
      <Select
          options={options}
          styles={customStyles}
          formatOptionLabel={formatOptionLabel}
          onChange={onChange}
          components={{DropdownIndicator: CustomDropdownIndicator}}
          defaultValue={defaultValue}
          isSearchable={isSearchable}
          placeholder={placeholder ? placeholder : "---Choose Item---"}
      />
  );
};

export default DropdownImgTitle;
