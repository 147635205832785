import {Fragment} from "react";
import ErrorElement from "./ErrorElement";

function ErrorPage() {
  return (
      <Fragment>
        <ErrorElement/>
      </Fragment>
  );
}

export default ErrorPage;
