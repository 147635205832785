import {mdiCheck, mdiPlus} from "@mdi/js";
import {Btn} from "../../../components/ui/Btn";
import styles from "./ConceptCard.module.scss"; // Import the SCSS module

function ConceptCard({concept, onSelect, onUnselect, onDelete}) {
  const handleSelect = () => {
    concept.selected ? onUnselect(concept) : onSelect(concept);
  }

  return (
      <div className={styles.conceptCard}>
        <div className={styles.header}>
          <h3 className={styles.name}>{concept.name}</h3>
          <div className={styles.priceContainer}>
            <span className={styles.price}>${concept.price}</span>
          </div>
        </div>
        <div className={styles.details}>
          <div className={styles.info}>
            <p>{concept.text}</p>
            {/*<div className={styles.meta}>*/}
            {/*  <span>Type: 2D</span>*/}
            {/*  <span>Duration: 22 sec</span>*/}
            {/*</div>*/}
          </div>
          <div className={styles.actions}>
            <Btn
                variant="outline"
                onClick={() => onDelete(concept)}
            >
              Delete
            </Btn>
            {/*<Btn*/}
            {/*    variant="outline-accent"*/}
            {/*    onClick={() => {}}*/}
            {/*>*/}
            {/*  Duplicate*/}
            {/*</Btn>*/}
            <Btn
                icon={concept.selected ? mdiCheck : mdiPlus}
                onClick={handleSelect}
                variant={concept.selected ? 'outline-accent' : 'default'}
            >
              {concept.selected ? 'In Order' : 'Add to Order'}
            </Btn>
          </div>
        </div>
      </div>
  );
}

export default ConceptCard;
