// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IssueStages_stagesList__DGKKf {
  padding-bottom: 3.5rem;
}
.IssueStages_stagesList__DGKKf .IssueStages_heading__nmA4E {
  font-weight: 500;
  font-size: 2.25rem;
  margin-bottom: 3rem;
}
.IssueStages_stagesList__DGKKf .IssueStages_link__q6WWS {
  text-decoration: underline;
  font-size: 24px;
  font-weight: 400;
  margin-left: 20px;
}
.IssueStages_stagesList__DGKKf .IssueStages_content__ZL0Zv {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/InWork/components/IssueStages.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;AACE;EACE,gBAAA;EACA,kBAAA;EACA,mBAAA;AACJ;AAEE;EACE,0BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAAJ;AAGE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AADJ","sourcesContent":[".stagesList {\n  padding-bottom: 3.5rem;\n\n  .heading {\n    font-weight: 500;\n    font-size: 2.25rem;\n    margin-bottom: 3rem;\n  }\n\n  .link {\n    text-decoration: underline;\n    font-size: 24px;\n    font-weight: 400;\n    margin-left: 20px;\n  }\n\n  .content {\n    display: flex;\n    flex-direction: column;\n    gap: 3rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stagesList": `IssueStages_stagesList__DGKKf`,
	"heading": `IssueStages_heading__nmA4E`,
	"link": `IssueStages_link__q6WWS`,
	"content": `IssueStages_content__ZL0Zv`
};
export default ___CSS_LOADER_EXPORT___;
