import {ProductForm} from "./components/ProductForm";
import styles from "./NewProduct.module.scss";
import {useLoaderData} from "react-router-dom";

function ProductDetail() {
  const data = useLoaderData();

  return (
      <section className={styles.newProductSection}>
        <div className={styles.container}>
          <h2 className={styles.title}>Product Detail</h2>
          <ProductForm apiProduct={data.product}/>
        </div>
      </section>
  )
}

export default ProductDetail;
