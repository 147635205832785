import { jwtDecode } from "jwt-decode";

export function setStorageToken(token) {
	localStorage.setItem("token", token);
}

export function validateTokenExpiration(token) {
  let decodedToken = jwtDecode(token);
  // console.log("Decoded Token", decodedToken);
  let currentDate = new Date();

  // JWT exp is in seconds
  return decodedToken.exp * 1000 >= currentDate.getTime();
}

export function removeToken() {
  localStorage.removeItem("token");
}

export function getToken() {
	let token = localStorage.getItem("token");

  if (token) {
    if (!validateTokenExpiration(token)) {
      removeToken();
      token = null;
    }
  }
	return token;
}