import { Link, useRouteError } from "react-router-dom";
import styles from "./ErrorElement.module.scss";

export default function ErrorElement() {
  const error = useRouteError();

  return (
    <div className={styles.pageWrap}>
      <div className={styles.container}>
        <h3 className={styles.title}>Error occurred!</h3>
        <span className={styles.statusCode}>{error.status}</span>
        <div className={styles.statusText}>{error.statusText}</div>
        <Link to="/profile" className={styles.backLink}>
          Back to Home
        </Link>
      </div>
    </div>
  );
}
