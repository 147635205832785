// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Stage_stage__dmWpF {
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
}
.Stage_stage__dmWpF .Stage_heading__NyqOd {
  font-weight: 500;
  font-size: 1.5rem;
  margin-bottom: 1.75rem;
}
.Stage_stage__dmWpF .Stage_description__S\\+nN1 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media (min-width: 1024px) {
  .Stage_stage__dmWpF .Stage_description__S\\+nN1 {
    flex-direction: row;
    gap: 3rem;
  }
}
.Stage_stage__dmWpF .Stage_descriptionText__awwMZ {
  max-width: 1080px;
  margin: 0;
  font-size: 1.5rem;
}
@media (min-width: 1024px) {
  .Stage_stage__dmWpF .Stage_descriptionText__awwMZ {
    padding-right: 5rem;
  }
}

.Stage_disabledText__RdDhY {
  color: var(--scroll-color);
}`, "",{"version":3,"sources":["webpack://./src/pages/InWork/components/Stage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AACF;AACE;EACE,gBAAA;EACA,iBAAA;EACA,sBAAA;AACJ;AAEE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAAJ;AAEI;EALF;IAMI,mBAAA;IACA,SAAA;EACJ;AACF;AACE;EACI,iBAAA;EACA,SAAA;EACF,iBAAA;AACJ;AAAM;EAJJ;IAKM,mBAAA;EAGN;AACF;;AACA;EACE,0BAAA;AAEF","sourcesContent":[".stage {\n  display: flex;\n  flex-direction: column;\n  gap: 1.75rem;\n\n  .heading {\n    font-weight: 500;\n    font-size: 1.5rem;\n    margin-bottom: 1.75rem;\n  }\n\n  .description {\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n\n    @media (min-width: 1024px) {\n      flex-direction: row;\n      gap: 3rem;\n    }\n  }\n  .descriptionText {\n      max-width: 1080px;\n      margin: 0;\n    font-size: 1.5rem;\n      @media (min-width: 1024px) {\n        padding-right: 5rem;\n      }\n    }\n}\n\n.disabledText {\n  color: var(--scroll-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stage": `Stage_stage__dmWpF`,
	"heading": `Stage_heading__NyqOd`,
	"description": `Stage_description__S+nN1`,
	"descriptionText": `Stage_descriptionText__awwMZ`,
	"disabledText": `Stage_disabledText__RdDhY`
};
export default ___CSS_LOADER_EXPORT___;
