import {requestPost, requestPut} from "../services/api";
import {PRODUCT_URL} from "./apiUrls";

export const parseProductData = async (url) => {
  const apiData = {
    google_url: url,
  };
  return await requestPost(PRODUCT_URL + "parse/", apiData);
}

export const createProduct = async (data) => await requestPost(PRODUCT_URL, data);
export const updateProduct = async (data, productId) => await requestPut(PRODUCT_URL + productId + '/', data);